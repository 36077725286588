import { PropsWithChildren, useEffect } from 'react';
import { imageList } from 'assets/images';
import { useImages } from 'hooks/useImages';

export const Preloader: React.FC<PropsWithChildren> = (props) => {
  const { loaded, preload } = useImages();

  useEffect(() => {
    preload(imageList);
  }, []);

	if (!loaded) {
		return (
					<div className="loaderContainer">
						<p> Loading...</p>
					</div>
		)
	}

	return (
		<>
			{props?.children}
		</>
		);
};
