import classNames from 'classnames';
import { memo } from 'react';
import logoImage from '../../../assets/images/crash-poker.png';
import fatguy from '../../../assets/images/fatguy.png';
import '../../../assets/scss/components/game/_gameLogo.scss';

// function blobToBase64(blob: Blob): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => {

//       const base64data = reader.result as string; // Type assertion
//       resolve(base64data);
//     };
//     reader.onerror = () => {
//       reject(new Error('Failed to read blob'));
//     };

//     reader.readAsDataURL(blob);
//   })

// }


export const GameLogo = memo(() => {
  // const { getArrayBuffer } = useImages();
  // const [fatguyurl, setfatguyurl] = useState('');

  // blobToBase64(getArrayBuffer(fatguy))
  //   .then((response) => {
  //     setfatguyurl(response);
  //     console.log("this is the data -->> ", response);
  //   }).catch((error) => {
  //     console.error("could not convert ", error);
  //   })

  return (
    <div className={classNames('gameLogo')}>
      <img src={logoImage} className="logoImage" alt=""></img>
      <img src={fatguy} className="fatguy" alt=""></img>
    </div>
  );
});

