import classNames from "classnames";
import { useAtomValue } from "jotai";
import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { isTMA } from "@telegram-apps/bridge";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  errorCodeAtom,
  showAutoPlayAtom,
  showHistoryReportAtom,
  stateAtom,
} from "./atoms";
import {
  AutoPlay,
  BetPanel,
  Bomb,
  CardsContainer,
  ErroPage,
  GameHistory,
  GameLogo,
  Header,
  HistoryReport,
  Loader,
  Multiplier,
  NotificationContainer,
} from "./components";
import { CashoutPopup } from "./components/game/CashoutNotification";
import { LandscapeLock } from "./components/game/landscapeLock";
import { GAME_STATES } from "./constants";
import { resizer, withStateManager } from "./utils";

function App() {
  const app = useRef(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const gameContainer = useRef(null);
  const [secondBetPanel, setSecondBetPanel] = useState(false);
  const [disableCloseBetPanel, setDisableCloseBetPanel] = useState(false);

  const errorCode = useAtomValue(errorCodeAtom);
  const showAutoplay = useAtomValue(showAutoPlayAtom);
  const showHistoryReport = useAtomValue(showHistoryReportAtom);
  const state = useAtomValue(stateAtom);

  const openSecondBetPanel = () => {
    if (disableCloseBetPanel) return;
    setSecondBetPanel(!secondBetPanel);
  };

  const onPlacingSecondBet = (value: boolean) => {
    setDisableCloseBetPanel(value);
    if (value) {
      buttonRef.current?.classList.add("disabled");
    } else {
      buttonRef.current?.classList.remove("disabled");
    }
  };

  useEffect(() => {
    if (app.current && gameContainer.current) {
      window.addEventListener("resize", () => {
        resizer(app.current!, gameContainer.current!);
      });
      setTimeout(() => {
        resizer(app.current!, gameContainer.current!);
      });
    }
  }, []);

  return (
    <div
      className={classNames("app", { mobile: isMobile || isTMA("simple") })}
      ref={app}
    >
      <LandscapeLock />
      <div className="gameContainer" ref={gameContainer}>
        {(!errorCode || errorCode === 401 || errorCode === 400) && <GameLogo />}
        <div
          className={classNames("head", {
            error: errorCode && errorCode !== 401 && errorCode !== 400,
          })}
        >
          <Header />
          {(!errorCode || errorCode === 401 || errorCode === 400) && (
            <GameHistory />
          )}
          <CashoutPopup />
        </div>
        {errorCode ? (
          <ErroPage />
        ) : (
          <Fragment>
            <Multiplier />
            <div className={classNames("content")}>
              <Bomb />
              <CardsContainer isSecondBetPanel={secondBetPanel} />
              <NotificationContainer />
              {state !== GAME_STATES.GD ? <Loader /> : null}
            </div>
            <div
              className={classNames("hudPanel", {
                secondBetPanel: secondBetPanel,
              })}
            >
              <div
                className={classNames("openButton", {
                  opened: secondBetPanel,
                })}
                onClick={openSecondBetPanel}
                ref={buttonRef}
              />
              {secondBetPanel ? (
                <BetPanel
                  id={1}
                  key="panel1"
                  show={secondBetPanel}
                  onPlacingSecondBet={onPlacingSecondBet}
                />
              ) : null}

              <BetPanel id={2} key="panel2" show={true} />
            </div>
            {showAutoplay && <AutoPlay />}
            {showHistoryReport && <HistoryReport />}
          </Fragment>
        )}
      </div>
      <ToastContainer
        // limit={1}
        position="top-center"
        autoClose={false}
        closeOnClick={true}
        pauseOnFocusLoss={true}
        pauseOnHover={true}
        transition={Bounce}
        draggable={true}
        hideProgressBar={false}
      />
      {/* <Leaderboard />
      <ChatBox /> */}
    </div>
  );
}

export default withStateManager(App);
