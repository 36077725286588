import { useAtomValue } from "jotai";
import { memo } from "react";
import { balanceAtom } from "../../../atoms";

export const Balance = memo(() => {
  const balance = useAtomValue(balanceAtom);
  return (
    <div className="balanceInfo">
      <p className="balance">ENERGY</p>
      {balance !== "" && <p className="value">{Number(balance).toFixed(2)}</p>}
    </div>
  );
});
