import classNames from 'classnames';
import { memo } from 'react';
import rotateImage from '../../../assets/images/mobile-landscape-to-portrait.png';
import '../../../assets/scss/components/game/_landscapeLock.scss';

export const LandscapeLock = memo(() => {
  return (
    <div className={classNames('landscapelock')}>
        <img src={rotateImage} alt="" className="lockImage" />
        <div className='lockTextContainer'>
          <div className='lockHeading'>Please rotate your device</div>
          <div className='lockDescription'>This game is optimised for portrait mode </div>
        </div>
      </div>
  );
});
