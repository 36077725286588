import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { errorCodeAtom } from '../../../atoms';
import { InsufficientFunds } from './insufficientFunds';
import { InternalServerError } from './internalServerError';
import { PageNotFound } from './pageNotFound';
import { SessionExpired } from './sessionExpired';

export const ErroPage = memo(() => {
  const errorCode = useAtomValue(errorCodeAtom);

  return errorCode && !['404', '401', '400'].includes(errorCode.toString()) ? (
    <InternalServerError />
  ) : errorCode && errorCode.toString() === '404' ? (
    <PageNotFound />
  ) : errorCode && errorCode.toString() === '401' ? (
    <SessionExpired />
  ) : errorCode && errorCode.toString() === '400' ? (
    <InsufficientFunds />
  ) : null;
});
