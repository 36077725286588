"use strict";

import EventEmitter from 'eventemitter2';
import Log          from './utils/log';
import CircularQ    from './circularq';

const log = new Log('sync', 'debug');

const HIST_SIZE           = 5;
const SYNC_PERIOD         = 5000;
const INITIAL_SYNC_PERIOD = 200;
const INITIAL_SYNC_BURST  = 5;

class ClockSync extends EventEmitter {
    #transport;
    #localTS;
    #serverTS;
    #latency;
    #offset;
	#jitter;
    #variance;
    #latencies;
	#offsets;
	#timer;

    constructor ({ transport }) {
		super ();
        this.#transport = transport;
        this.#latencies = new CircularQ (HIST_SIZE);
        this.#offsets   = new CircularQ (HIST_SIZE);
    }

    async start () {
        for (let i = 0; i < INITIAL_SYNC_BURST; i++) {
            await this.#sync ()
            await this.#sleep (INITIAL_SYNC_PERIOD);
        }

		this.#syncNormal ();
    }

    stop () {
        if (this.#timer) {
            clearInterval (this.#timer);
            this.#timer = null;
        }
    }

    getLocalTimeFromServerTS (ts) {
        let serverTs = ts;

        if (typeof ts === 'string')
            serverTs = (new Date (ts)).getTime ();

        return serverTs - this.#offset;
    }

	#syncNormal () {
		this.#timer = setInterval (() => this.#sync (), SYNC_PERIOD);
	}

    #sleep (ms) {
        return new Promise ((resolve) => setTimeout (() => resolve (), ms));
    }

	async #sync () {
		/*
		 * This is just a wrapper to catch any exceptions if thrown
		 * by the #_sync method.
		 */

		try {
			await this.#_sync ();
		}
		catch (e) {
			log.error ('sync failed : ', e);
		}
	}

	async #_sync () {
		const { serverTS, latency, localTS } = await this._sendSync ();

		const _s       = new Date (serverTS).getTime();
		this.#serverTS = new Date (_s + latency)
		this.#localTS  = localTS;

		this.#calcAveOffset (this.#serverTS - this.#localTS);
		this.#calcAveLatency (latency);

		this.emit ('clock/sync', {
			latency     : this.#latency,
			offset      : this.#offset,
            jitter      : this.#jitter,
            lastLatency : latency,
		});
	}

	async _sendSync () {
		const txTS = new Date();

		const response = await this.#transport.request (
			/* command */   'sync',
			/* data */      {
				latency: this.#latency,
				localTS: txTS.toISOString (),
				jitter:  this.#jitter,
				offset:  this.#offset,
			},
		);

		const rxTS    = new Date();
		const latency = (rxTS - txTS)/2;

		return { ...response, localTS: rxTS, latency };
    }

	#calcAveLatency (latency) {
        this.#latencies.push (latency)
        this.#latency = this.#latencies.average;

        // Calculate Jitter
        const summation = this.#latencies.array.reduce ((acc, d) => acc + Math.pow (d - this.#latency, 2), 0);
        this.#jitter = Math.sqrt (summation/this.#latencies.size);
	}

	#calcAveOffset (offset) {
        this.#offsets.push (offset)
        this.#offset = this.#offsets.average
	}

}

export default ClockSync;
