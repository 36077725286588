"use strict";

class CircularQ {
    #max;
    #Q;

    constructor (max) {
        this.#max = max;
        this.#Q   = [];
    }

    push (el) {
        this.#Q.push (el);

        if (this.#Q.length > this.#max)
            this.#Q.shift ();
    }

    get array ()    { return this.#Q; }
    get size ()    { return this.#Q.length; }
    get last ()    { return this.#Q[this.#Q.length -1]; }
	get average () {
		let acc = 0;

		for (let i = 0; i < this.#Q.length; i++) {
			acc += this.#Q[i];
		}

		return acc/this.#Q.length;
	}
}

export default CircularQ;
