import { atom } from "jotai";
import { INotification } from "../components";
import { isMobile } from "react-device-detect";
import { isTMA } from "@telegram-apps/bridge";
import { ICardProps } from "../components/game/cards/card";
import { MultiHistory } from "../components/game/gameHistory/gameHistory";
import { IPotAndBustProps } from "../components/game/header/potAndBust";
import { CONN_STATES, GAME_STATES, HISTORY_SIZE } from "../constants";

export const playerIdAtom = atom<string>("");

export const connectionStateAtom = atom<CONN_STATES>(CONN_STATES.NOTCONNECTED);

export const isAuthenticatedAtom = atom<boolean>(false);

export const multiplierAtom = atom<string>("");

export const multiplierIdAtom = atom<string>("");

export const roundIdAtom = atom<string>("");

export const stateAtom = atom<GAME_STATES>(GAME_STATES.NOT_READY);

export const messageAtom = atom<string>("");

export const heroCardsAtom = atom<ICardProps[]>([]);

export const villianCardsAtom = atom<ICardProps[]>([]);

export const communityCardsAtom = atom<ICardProps[]>([]);

export const crashAtom = atom<boolean>(false);

export const isMobilePortraitAtom = atom<boolean>(
  (isMobile || isTMA("simple")) && window.innerHeight > window.innerWidth,
);

export const gameIsStartedAtom = atom<boolean>(false);

export const balanceAtom = atom<string>("");

export const betsArrayAtom = atom<number[]>([1, 2, 5, 10]);

export const errorCodeAtom = atom<number | null>(null);

export const refreshAtom = atom<boolean>(false);

export const showAutoPlayAtom = atom<boolean>(false);

export const showHistoryReportAtom = atom<boolean>(false);

export const potAndBustAtom = atom<IPotAndBustProps>({
  bet: 0,
  cashout: 0,
  pnb: {
    mini: 0,
    avg: 0,
    big: 0,
    monster: 0,
    bust: 0,
  },
});

export const potAndBustHistory = atom<IPotAndBustProps[]>([]);

export const multiHistory = atom<MultiHistory[]>([]);

export const notificationDetailsAtom = atom<INotification>({
  villian: new Set(),
  hero: new Set(),
} as INotification);

export const betValueAtom = atom<number>(1);

export const countDownAtom = atom<number | null>(null);

export const isMuteAtom = atom<boolean>(true);

export const historyItemsToFetchAtom = atom<number>(
  HISTORY_SIZE.MULTIPLIER.MIN,
);

export const possibleVillianNotification = atom(
  (get) =>
    new Set(
      get(villianCardsAtom).map((card) => card.suit[0].toLocaleLowerCase()),
    ),
);

export const cashoutNotifyAtom = atom(false);
export const cashoutDataAtom = atom({ multiplier: 0, amount: 0 });
