// @ts-nocheck
const images = require.context('.', true);
export const imageList = images.keys().map((image: any) => images(image));

// let imageList: string[] = [];
// const base64Images: { [key: string]: string } = {};
// async function imageToBase64(images: any): Promise<{ [key: string]: string }> {
  

//   for (const key of images.keys()) {
//       try {
//           const img = await fetch(images(key)); // Fetch the image using the correct URL
//           const blobimg = await img.blob();

//           const reader = new FileReader();

//           const base64data = await new Promise<string>((resolve, reject) => {
//               reader.onloadend = () => {
//                   const base64data = reader.result as string; // Type assertion
//                   base64Images[key] = base64data;
//                   resolve(base64data);
//               };

//               reader.onerror = () => {
//                   reject(new Error('Failed to convert Blob to Base64'));
//               };

//               reader.readAsDataURL(blobimg); // Use blobimg here
//           });

//       } catch (error) {
//           console.error(`Failed to convert image ${key} to Base64:`, error);
//       }
//   }

//   return base64Images; // Return the object containing all base64 images
// }

// let imageListPromise: Promise<string[]>;

// const loadImages = async () => {
//   const base64Images = await imageToBase64(images);
//   return Object.values(base64Images);
// };

// imageListPromise = loadImages();

// export const getImageList = async (): Promise<string[]> => {
//     return imageListPromise; // Returns the promise
// };

