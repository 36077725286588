import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { errorCodeAtom, refreshAtom } from '../../../atoms';
import { Error } from '../../shared';

export const InternalServerError = memo(() => {
  const [errorCode, setErrorCode] = useAtom(errorCodeAtom);
  const [, setRefresh] = useAtom(refreshAtom);

  let errorInfo;

  if (errorCode === 4002) {
    errorInfo = "You are logged in on another device. This session has been logged out.";
  } else{
    errorInfo = "Connection to the server has been lost. Please check your internet connection.";
  }

  const onClickErrorButton = useCallback(() => {
    setErrorCode(null);
    setRefresh(true);
    window.location.reload();
  }, [setErrorCode, setRefresh]);

  return (
    <Error
      errorCode=""
      errorMessage=""
      className="internalServerError"
      buttonLabel="RECONNECT"
      errorInfo={errorInfo}
      onClickErrorButton={onClickErrorButton}
    />
  );
});
