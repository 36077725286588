import { memo, useCallback } from 'react';
import { Error } from '../../shared';

export const PageNotFound = memo(() => {
  const onClickErrorButton = useCallback(() => {
    console.log('Handle page not found error');
  }, []);

  return (
    <Error
      className="pageNotFound"
      buttonLabel="GO BACK"
      errorCode="404"
      errorMessage="Page not found"
      errorInfo="The page you are looking for doesn’t exist ."
      onClickErrorButton={onClickErrorButton}
    />
  );
});
