export const LOW_LATENCY_THRESHOLD = 100;
export const LOW_JITTER_THRESHOLD = 100;
export const REFERENCE_WIDTH = 1440;
export const REFERENCE_HEIGHT = 800;
export const HISTORY_SIZE = {
  MULTIPLIER: {
    MIN: 14,
    MAX: 55,
  },
  POT_AND_BUST: {
    MAX: 4,
  },
};

export const AUTOPLAY_SETTING = {
  INCREMENTBY: 0.2,
  DECREMENTBY: 0.2,
};

export const INIT_BETVALUE = 1;

export enum CONN_STATES {
  NOTCONNECTED = 'notconncetd',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}

export enum GAME_STATES {
  NOT_READY = 'Initializing',
  STARTING = 'starting',
  BWO = 'bwo',
  BWC = 'bwc',
  GD = 'gd',
}

export enum BUTTON_STATES {
  NOT_READY = 'Initializing',
  ERROR = 'BetError',
  BET = 'Bet',
  BETTING = 'Betting',
  CANCELLABLE_REMOTE = 'CancellableRemote',
  CANCELLABLE_LOCAL = 'CancellableLocal',
  CANCELLING = 'Cancelling',
  CASHOUT = 'Cashout',
  CASHING_OUT = 'CachngOut',
  CANCEL_PENDING = 'CancelPending',
  CANCEL_ACCEPTED = 'CancelAccepted',
}
