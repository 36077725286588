import { useState } from "react";
import { ImageRecord } from "types/image";

let _record: ImageRecord = {};

export const useImages = () => {
    const [loaded, setLoaded] = useState(false);

    const getKey = (s: string) => {
        return s.toString().split("/").slice(-1)[0];
    };

    const preload = async (imageList: string[]) => {
        for (const image of imageList) {
            const key = getKey(image);

            if (!_record[key]) {
                await fetch(image)
                    .then((response) => {
                        return response.blob();
                    })
                    .then((imageblob) => {
                        _record[key] = imageblob;
                    });
            }
        }

        setLoaded(true);
    };

    const getBlob = (s: string) => {
        const key = getKey(s);
        return _record[key];
    };

    return {
        loaded,
        preload,
        getBlob,
    };
};
