import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo } from 'react';
import '../../../assets/scss/components/game/_notification.scss';
import {
  notificationDetailsAtom,
  possibleVillianNotification,
} from '../../../atoms';
import { HeroNotification } from './heroNotfication';
import { VillianNotification } from './villianNotification';

export type Suit = 's' | 'c' | 'd' | 'h';
export interface INotification {
  villian: Set<Suit>;
  hero: Set<Suit>;
}

export const NotificationContainer = memo(() => {
  const notification = useAtomValue(notificationDetailsAtom);
  const villianCards = useAtomValue(possibleVillianNotification);

  return (
    <div className={classNames('notificationContainer')}>
      <div className="notificationTab">
        {Array.from(villianCards).map((s: string) => (
          <VillianNotification
            key={`${s}-${Math.random}`}
            suit={s as Suit}
            show={notification.villian.has(s as Suit)}
          />
        ))}

        {notification.hero.size ? <HeroNotification /> : null}
      </div>
    </div>
  );
});
