import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { AUTOPLAY_SETTING } from '../../../constants';
import { Button, CheckBox } from '../../shared';

const MIN_AMOUNT = 0.01;
const MAX_AMOUNT = 10000;
const STEP_AMOUNT = 0.01;
const PRECISION = 2;

interface IAutoPlayOptionsProps {
  value: number;
  enabled: boolean;
  label: string;
  onClick: (value: number) => void;
  onChange: (value: boolean) => void;
}
export function AutoPlayOptions(props: IAutoPlayOptionsProps) {
  const [value, setValue] = useState(props.value.toFixed(2));
  const onClickCheckBox = (value: boolean) => {
    props.onChange(value);
  };
  const onClickIncrement = () => {
    props.onClick(props.value + AUTOPLAY_SETTING.INCREMENTBY);
  };
  const onClickDecrement = () => {
    if (props.value > AUTOPLAY_SETTING.INCREMENTBY) {
      props.onClick(props.value - AUTOPLAY_SETTING.DECREMENTBY);
    }
  };
  const onChangeInput = (evt: ChangeEvent) => {
    let value = (evt.currentTarget as HTMLInputElement).value;

    if (value === '') {
      setValue('');
      return;
    }

    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1];
    } else {
      value = sanitizedValue;
    }

    setValue(value);
  };

  const onBlurInput = () => {
    let a = Number(value);
    if (a > MAX_AMOUNT) {
      a = MAX_AMOUNT;
    }
    if (a < MIN_AMOUNT) {
        a = 0;
    }
    const b = Number(a).toFixed(2);
    props.onClick(Number(b));
  };

  useEffect(() => {
    setValue(Number(props.value).toFixed(2));
  }, [props.value]);

  return (
    <div className={classNames('option', { enabled: props.enabled })}>
      <CheckBox
        checked={props.enabled}
        className="oval"
        label={props.label}
        onClick={onClickCheckBox}
      />
      <div className="panel">
        <Button type="oval" label="-" onClick={onClickDecrement} />
        {/* <span>{props.value.toFixed(2)}</span> */}
        <input
          type="text"
          step={STEP_AMOUNT.toFixed(PRECISION)}
          inputMode='decimal'
          onChange={onChangeInput}
          onBlur={onBlurInput}
          value={value}
        />
        <Button type="oval" label="+" onClick={onClickIncrement} />
      </div>
    </div>
  );
}
