import classNames from 'classnames';
import { memo } from 'react';
import '../../../assets/scss/components/game/_card.scss';
import { CommunityCards } from './communityCards';
import { HeroCards } from './heroCards';
import { VillianCards } from './villianCards';

interface CardsContainerProps {
  isSecondBetPanel: boolean;
}

export const CardsContainer = memo(({ isSecondBetPanel }: CardsContainerProps) => {
  return (
    <div className={classNames('cardsContainer', { isSecondBetPanel })}>
      <div className="heroAndPlayerCardContainer">
        <HeroCards />
        <VillianCards />
      </div>
      <CommunityCards />
    </div>
  );
});
