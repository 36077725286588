import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useRef } from 'react';
import {
  communityCardsAtom,
  heroCardsAtom,
  notificationDetailsAtom,
} from '../../../atoms';
import { EventEmitter } from '../../../utils';
import { SoundManager } from '../../../utils/soundManager';
import { Suit } from '../notification';
import { Card } from './card';

export const CommunityCards = memo(() => {
  const communityCards = useAtomValue(communityCardsAtom);
  const heroCards = useAtomValue(heroCardsAtom);
  const notification = useAtomValue(notificationDetailsAtom);
  const containerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef(null);

  if (communityCards.length) {
    SoundManager.stop('Dealing-cards-3');
    SoundManager.play('Dealing-cards-3', false);
  }

  // useEffect(() => {
  //   if(containerRef.current) {
  //     const container = containerRef.current as HTMLDivElement;
  //     if()
  //   }
  // }, [communityCards]);

  if (heroCards.length) {
    heroCards.forEach((card, index) => {
      if (notification.villian.has(card.suit[0].toLowerCase() as Suit)) {
        EventEmitter.getInstance().emit('dimCards', index);
      }
    });
  }

  return (
    <div
      className={classNames('communityCardsContainer')}
      ref={containerRef}
    >
      <div className={classNames('communityCardBox')}>
        {communityCards.map((detail, index: number) => (
          <Card
            className={classNames(
              notification.villian.has(detail.suit[0].toLowerCase() as Suit)
                ? ''
                : 'gray',
              communityCards.length > 5
                ? index < 5
                  ? 'moveLeft'
                  : 'fixedCard'
                : ''
            )}
            firstCard={communityCards.length > 5 && index === 0}
            key={`${detail.suit}-${detail.value}${Math.random()}`}
            hidden={index === communityCards.length - 1}
            ref={cardRef}
            {...detail}
          />
        ))}
      </div>
    </div>
  );
});
