import classNames from 'classnames';
import { memo, useEffect, useRef } from 'react';
import heroImage from '../../../assets/images/animations/biglock.png';
import { SoundManager } from '../../../utils';

export const HeroNotification = memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    SoundManager.play('Lock');
    if (ref.current) {
      ref.current.style.backgroundImage = `url("${heroImage}?${Math.random()}")`;
    }
    setTimeout(() => {
      console.log('image in used for hero icon is static');
      ref.current && ref.current.classList.add('static');
    }, 1800);
  }, []);

  return <div className={classNames('hero', 'show')} ref={ref} />;
});
