import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useEffect, useRef } from 'react';
import { crashAtom, heroCardsAtom, stateAtom } from '../../../atoms';
import { GAME_STATES } from '../../../constants';
import { EventEmitter } from '../../../utils';
import { SoundManager } from '../../../utils/soundManager';
import { Card } from './card';

export const HeroCards = memo(() => {
  const heroCards = useAtomValue(heroCardsAtom);
  const crash = useAtomValue(crashAtom);
  const state = useAtomValue(stateAtom);

  const cardRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);

  if (heroCards.length) {
    SoundManager.stop('Dealing-cards-1');
    SoundManager.play('Dealing-cards-1', false);
  }

  useEffect(() => {
    EventEmitter.getInstance().addListener('dimCards', dimCards);
    return () => {
      EventEmitter.getInstance().removeListener('dimCards', dimCards);
    };
  });

  const dimCards = (index: number) => {
    if (containerRef.current) {
      const node = containerRef.current.childNodes[index] as HTMLDivElement;
      node && node.classList.add('gray');
    }
  };

  return (
    <div className={classNames('playerCard', { crash: crash })}>
      <div className={classNames('avatar')}>
        <span className="hero">HERO</span>
      </div>
      <div className="heroCardsContainer" ref={containerRef}>
        {heroCards.map((detail, index: number) => (
          <Card
            key={`${detail.suit}-${detail.value}${Math.random()}`}
            {...detail}
            hidden={index === heroCards.length - 1 && state !== GAME_STATES.GD}
            ref={cardRef}
          />
        ))}
      </div>
    </div>
  );
});
