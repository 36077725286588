import { isMobile } from "react-device-detect";
import { isTMA } from "@telegram-apps/bridge";
import { REFERENCE_WIDTH } from "../constants";

export function resizer(
  referenceContainer: HTMLDivElement,
  resizeContainer: HTMLDivElement,
) {
  const WIDTH = REFERENCE_WIDTH;
  const MAX_WIDTH =
    isMobile || isTMA("simple") ? referenceContainer.clientWidth : 1440;
  const MAX_HEIGHT =
    isMobile || isTMA("simple") ? referenceContainer.clientHeight : 800;
  let scale: number;

  if (
    window.innerWidth > window.innerHeight ||
    !(isMobile || isTMA("simple"))
  ) {
    resizeContainer.style.width = `${WIDTH}px`;
    resizeContainer.style.height = `${(MAX_HEIGHT * WIDTH) / MAX_WIDTH}px`;
    scale =
      isMobile || isTMA("simple")
        ? MAX_WIDTH / WIDTH
        : Math.min(
            window.innerHeight / MAX_HEIGHT,
            window.innerWidth / MAX_WIDTH,
          );
  } else {
    resizeContainer.style.width = `${(MAX_WIDTH * WIDTH) / MAX_HEIGHT}px`;
    resizeContainer.style.height = `${WIDTH}px`;
    scale = MAX_HEIGHT / WIDTH;
  }
  resizeContainer.style.transform = `scale(${scale})`;
}
