import EventEmitter from 'eventemitter2';
import Log          from './utils/log';

const log = new Log ('jb', 'info');

class JitterBuf extends EventEmitter {
    #transport;
    #hold;
    #q;
    #timer;
    #sync;
    #minGap;
    #maxQSize;

    constructor ({ hold, transport, sync }) {
        super ();

        if (!transport)
            throw new Error ('insufficient params');

        this.#hold      = hold;
        this.#transport = transport;
        this.#sync      = sync;
        this.#q         = [];
        this.#maxQSize  = 3;

        this.#transport.on ('info/starting', this.push.bind (this));
        this.#transport.on ('info/bwo', this.push.bind (this));
        this.#transport.on ('info/bwc', this.push.bind (this));
        this.#transport.on ('info/gd', this.push.bind (this));
    }

    set hold (h)   { this.#hold = h; }
    set sync (s)   { this.#sync = s; }
    set minGap (s) { this.#minGap = s; }

    push (data, pdu) {
        if (this.#q.length >= this.#maxQSize) {
					this.#q.splice (0, 1);
        }
        this.#q.push ({ data, pdu });
        //log.debug ('pushed', data.multi?.value)

        if (!this.#sync || this.#timer)
            return;

        this.#scheduleNext ();
    }

    #timerCB (item) {
        const { data, pdu } = item;

        const m = data.multi?.value || '';
        const s = data.street ? `s${data.street.street}` : '';

        this.emit (`info/${pdu.id}`, data);
        log.debug ('emitted', m, s)

        this.#timer = null;
        this.#scheduleNext ();
    }

    #scheduleNext () {
        const next = this.#q.shift ();

        if (!next) {
            log.debug ('q is empty')
            return;
        }

        const { startTS, seq, offset } = next.data;
        const localTS  = this.#sync.getLocalTimeFromServerTS (startTS);
        const localPTS = localTS + offset + this.#hold;

        const now  = (new Date ()).getTime ();
        let  delay = localPTS - now;

        const m = next.data.multi?.value || '';
        const s = next.data.street ? `s${next.data.street.street}` : '';

        log.debug ('delay =', delay, m, s)

        if (delay < 0)
            delay = 0;

        this.#timer = setTimeout (() => this.#timerCB (next), delay);
    }
}

export default JitterBuf;
