import classNames from 'classnames';
import { forwardRef, MutableRefObject } from 'react';

export interface ICardProps {
  type: string;
  suit: string;
  value: string;
  ref?: MutableRefObject<any> | null;
  hidden?: boolean;
  className?: string;
  firstCard?: boolean;
}

export const Card = forwardRef(function (props: ICardProps, ref: any) {
  return (
    <div
      className={classNames(
        'card',
        props.type,
        props.suit,
        props.className,
        `card_${props.value}`,
        { hidden: props.hidden },
        { firstCard: props.firstCard }
      )}
      ref={ref}
    ></div>
  );
});
