import classNames from "classnames";
import { useAtom, useAtomValue } from "jotai";
import { memo, useCallback, useEffect, useState } from "react";
import "../../../assets/scss/components/game/_betBox.scss";
import {
  betsArrayAtom,
  betValueAtom,
  crashAtom,
  isAuthenticatedAtom,
  multiplierAtom,
  multiplierIdAtom,
  stateAtom,
} from "../../../atoms";
import { BUTTON_STATES, GAME_STATES, INIT_BETVALUE } from "../../../constants";
import {
  EventEmitter,
  getBetButtonStateFromStatus,
  SoundManager,
} from "../../../utils";
import { Button, ButtonTypes, CheckBox } from "../../shared";
import { AutoplayButtons } from "./AutoplayButtons";
import { ButtonPanel } from "./buttonPanel";

interface IBetPanelProps {
  className?: string;
  show: boolean;
  id: number;
  onPlacingSecondBet?: (value: boolean) => void;
}
const minBetAmount = 0.1;
const maxBetAmount = 100;

export const BetPanel = memo((props: IBetPanelProps) => {
  const [buttonState, setButtonState] = useState<BUTTON_STATES>(
    BUTTON_STATES.NOT_READY,
  );
  const [isAutoplay, setIsAutoplay] = useState(false);
  const [betValue, setBetValue] = useState(INIT_BETVALUE);
  const [crashedMultiplier, setCashedMultiplier] = useState(0);
  const [transactionId, setTransactionId] = useState("");
  const [, setBetStatus] = useState<Record<string, any>>({});
  const [betId, setBetId] = useState("");
  const [autoPlayData, setAutoPlayData] = useState<Record<string, any>>({});
  const [roundId, setRoundId] = useState("");
  const [autoCashData, setAutoCashData] = useState<Record<string, any>>({});

  const state = useAtomValue(stateAtom);
  const crash = useAtomValue(crashAtom);
  const multiplier = useAtomValue(multiplierAtom);
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const betsArray = useAtomValue(betsArrayAtom);
  const multiplierId = useAtomValue(multiplierIdAtom);
  const [, setBet] = useAtom(betValueAtom);

  const buttonLabel =
    buttonState === BUTTON_STATES.CANCELLABLE_LOCAL ||
    buttonState === BUTTON_STATES.CANCELLABLE_REMOTE ||
    buttonState === BUTTON_STATES.CANCEL_ACCEPTED ||
    buttonState === BUTTON_STATES.CANCEL_PENDING
      ? "CANCEL"
      : buttonState === BUTTON_STATES.CASHOUT ||
          buttonState === BUTTON_STATES.CASHING_OUT
        ? `CASH OUT`
        : "BET";

  const buttonValue =
    buttonState === BUTTON_STATES.CANCELLABLE_LOCAL ||
    buttonState === BUTTON_STATES.CANCELLABLE_REMOTE ||
    buttonState === BUTTON_STATES.CANCEL_ACCEPTED ||
    buttonState === BUTTON_STATES.CANCEL_PENDING
      ? ""
      : buttonState === BUTTON_STATES.CASHOUT
        ? `${Number(multiplier) * betValue}`
        : buttonState === BUTTON_STATES.CASHING_OUT
          ? crashedMultiplier
          : betValue;
  const currency =
    buttonState === BUTTON_STATES.CANCELLABLE_LOCAL ||
    buttonState === BUTTON_STATES.CANCELLABLE_REMOTE ||
    buttonState === BUTTON_STATES.CANCEL_ACCEPTED ||
    buttonState === BUTTON_STATES.CANCEL_PENDING
      ? ""
      : "USD";

  function resetBetButton() {
    setButtonState(BUTTON_STATES.BET);
    setAutoPlayData({ status: "off" });
  }

  useEffect(() => {
    if (isAuthenticated) resetBetButton();
  }, [isAuthenticated]);

  useEffect(() => {
    EventEmitter.getInstance().addListener(
      `betStatusInfo${props.id}`,
      setBetStatusInfo,
    );

    return () => {
      EventEmitter.getInstance().removeListener(
        `betStatusInfo${props.id}`,
        setBetStatusInfo,
      );
    };
  }, []);

  useEffect(() => {
    if (buttonState === BUTTON_STATES.NOT_READY) return;

    switch (state) {
      case GAME_STATES.BWO:
        break;
      case GAME_STATES.GD:
        if (crash) {
          if (
            buttonState === BUTTON_STATES.CASHOUT ||
            buttonState === BUTTON_STATES.CANCEL_ACCEPTED
          )
            return setButtonState(
              autoPlayData?.status === "on"
                ? BUTTON_STATES.CANCEL_PENDING
                : BUTTON_STATES.BET,
            );
          return;
        }
        // Else no crash
        if (buttonState == BUTTON_STATES.CANCEL_ACCEPTED)
          return setButtonState(BUTTON_STATES.CASHOUT);

        break;
    }
  }, [state, crash, buttonState, autoPlayData]);

  useEffect(() => {
    if (state === GAME_STATES.GD && buttonState === BUTTON_STATES.CASHOUT)
      SoundManager.play("Button_Confirm_Click", false);
  }, [state, buttonState]);

  useEffect(() => {
    if (
      props.onPlacingSecondBet &&
      ((autoPlayData && autoPlayData.status === "on") ||
        buttonState !== BUTTON_STATES.BET ||
        (autoCashData && autoCashData.state === "on"))
    ) {
      props.onPlacingSecondBet(true);
    } else {
      props.onPlacingSecondBet && props.onPlacingSecondBet(false);
    }
  }, [autoPlayData, buttonState, autoCashData]);

  const setBetStatusInfo = (data: any) => {
    console.log("setting bet status info", data);
    const { status, autoBet, betId, roundId, autoCashout } = data;
    setAutoPlayData(autoBet);
    setAutoCashData(autoCashout);
    setBetId(betId);
    switch (status) {
      case "pending":
        setButtonState(BUTTON_STATES.CANCEL_PENDING);
        break;
      case "cashout-sent":
        setButtonState(
          autoBet.status === "off"
            ? BUTTON_STATES.BET
            : BUTTON_STATES.CANCEL_PENDING,
        );
        break;
      case "ready":
      case "error":
      case "bet-error":
        resetBetButton();
        break;
      case "cashout-error":
        resetBetButton();
        setAutoCashData({ state: "off" });
        break;
      case "accepted":
        setRoundId(roundId);
        setButtonState(BUTTON_STATES.CANCEL_ACCEPTED);
        break;
    }
  };

  const onBetChange = useCallback(
    (value: number | string) => {
      setBetValue(Number(value));
      setBet(Number(value));
    },
    [betValue, autoPlayData?.status],
  );

  const onClickIncrement = useCallback(() => {
    if (betValue <= 99.9) {
      setBetValue(betValue + 0.1);
      setBet(betValue + 0.1);
    } else if (betValue < maxBetAmount) {
      setBetValue(maxBetAmount);
    }
  }, [betValue, autoPlayData?.status, state]);

  const onClickDecrement = useCallback(() => {
    if (betValue >= 0.2) {
      setBetValue(betValue - 0.1);
      setBet(betValue - 0.1);
    } else if (betValue > minBetAmount) {
      setBetValue(minBetAmount);
    }
  }, [betValue, autoPlayData?.status, state]);

  const onClickPlaceBetButton = async () => {
    if (isBusy()) return;

    let response: any;

    try {
      switch (buttonState) {
        case BUTTON_STATES.ERROR:
        case BUTTON_STATES.BET:
          setButtonState(BUTTON_STATES.BETTING);
          [response] = await EventEmitter.getInstance().emitAsync("placeBet", {
            amount: betValue,
            buttonId: props.id,
          });
          if (response.error) throw response.error;

          const { status: b_s, betId: b_bid, betTxn: b_txId } = response;
          setButtonState(
            b_s === "pending"
              ? BUTTON_STATES.CANCEL_PENDING
              : BUTTON_STATES.CANCEL_ACCEPTED,
          );
          b_txId && setTransactionId(b_txId.id);
          setBetId(b_bid);
          setBetStatus(b_s);
          break;

        case BUTTON_STATES.CANCEL_PENDING:
        case BUTTON_STATES.CANCEL_ACCEPTED:
          setButtonState(BUTTON_STATES.CANCELLING);

          if (autoPlayData && autoPlayData.status === "on") {
            [response] = await EventEmitter.getInstance().emitAsync(
              "autoplayStop",
              {
                buttonId: props.id,
              },
            );
          } else {
            [response] = await EventEmitter.getInstance().emitAsync(
              "cancelBet",
              {
                buttonId: props.id,
                txnId: transactionId,
                betId,
                roundId,
              },
            );

            if (response.errorOccured) throw response;
            const { status: c_s, betStatus } = response;
            const { auto } = betStatus;

            setBetStatus(c_s);
            setAutoPlayData(auto);
            setButtonState(getBetButtonStateFromStatus(betStatus.status));
          }
          break;

        case BUTTON_STATES.CASHOUT:
          if (state === GAME_STATES.GD && !crash) {
            setCashedMultiplier(Number(buttonValue));
            setButtonState(BUTTON_STATES.CASHING_OUT);
            console.log("cashing out round", roundId);
            [response] = await EventEmitter.getInstance().emitAsync(
              "cashOutBet",
              {
                multiplier: multiplier,
                multiplierId,
                buttonId: props.id,
                betId,
                roundId,
              },
            );
            const { status: c_s, betStatus } = response;
            const { auto } = betStatus;
            setBetStatus(c_s);
            setAutoPlayData(auto);
            setButtonState(
              autoPlayData && autoPlayData.status === "on"
                ? BUTTON_STATES.CANCEL_PENDING
                : BUTTON_STATES.BET,
            );
          } else {
            resetBetButton();
          }
          break;

        default:
          console.error(
            `click in an illegal btnState = "${buttonState}", gameState="${state}", crash="${crash}"`,
          );
      }
    } catch (e: any) {
      resetBetButton();
    }
  };

  const onClickBetButtons = useCallback(
    (value: number) => {
      setBetValue(value);
      setBet(value);
    },
    [state, setBetValue],
  );

  const isBusy = () => {
    return (
      buttonState === BUTTON_STATES.NOT_READY ||
      buttonState === BUTTON_STATES.BETTING ||
      buttonState === BUTTON_STATES.CANCELLING ||
      buttonState === BUTTON_STATES.CASHING_OUT
    );
  };

  const _betButtonState = (): ButtonTypes => {
    switch (buttonState) {
      case BUTTON_STATES.NOT_READY:
      case BUTTON_STATES.BETTING:
      case BUTTON_STATES.CANCELLING:
      case BUTTON_STATES.CASHING_OUT:
        return "disabled";
      case BUTTON_STATES.CANCELLABLE_LOCAL:
      case BUTTON_STATES.CANCELLABLE_REMOTE:
      case BUTTON_STATES.CANCEL_ACCEPTED:
        return "cancelBet";
      case BUTTON_STATES.CANCEL_PENDING:
        return "cancelPending";
      case BUTTON_STATES.ERROR:
      case BUTTON_STATES.BET:
        return "placeBet";
      case BUTTON_STATES.CASHOUT:
        return "cashBet";
    }
  };

  const onClickBetOptions = (value: boolean) => {
    setIsAutoplay(value);
  };

  return (
    <div
      className={classNames(
        "betbox",
        props.className,
        { show: props.show },
        { autoplay: isAutoplay },
      )}
    >
      <CheckBox
        checked={isAutoplay}
        checkedText="BET"
        unCheckedText="AUTO"
        onClick={onClickBetOptions}
        disabled={
          (autoCashData && autoCashData.state === "on") ||
          (autoPlayData && autoPlayData.status === "on")
        }
      />
      <div className={classNames("betPanel", { autoplay: isAutoplay })}>
        <ButtonPanel
          onClickDecrement={onClickDecrement}
          onClickIncrement={onClickIncrement}
          betValue={betValue}
          onClickBetButtons={onClickBetButtons}
          onBetChange={onBetChange}
          disabled={buttonState !== BUTTON_STATES.BET}
        />
        <div className="betbox-button">
          {buttonState === BUTTON_STATES.CANCEL_PENDING && (
            <p>Waiting for next round…</p>
          )}
          <Button
            type={_betButtonState()}
            label={buttonLabel}
            value={buttonValue}
            currency={currency}
            onClick={onClickPlaceBetButton}
          />
        </div>
      </div>
      {isAutoplay && (
        <AutoplayButtons
          bet={buttonValue}
          buttonId={props.id}
          autoPlayData={autoPlayData}
          isDisabled={buttonState !== BUTTON_STATES.BET}
          autoCashData={autoCashData}
          onChangeAutoCashout={setAutoCashData}
        />
      )}
    </div>
  );
});
