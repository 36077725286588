import EventEmitter2 from 'eventemitter2';

export class EventEmitter extends EventEmitter2 {
  private static instance: EventEmitter;

  private constructor() {
    super();
  }

  static getInstance = (): EventEmitter => {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }
    return EventEmitter.instance;
  };
}
