import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useEffect, useRef } from 'react';
import '../../../assets/scss/components/game/_bomb.scss';
import { crashAtom, stateAtom } from '../../../atoms';
import { GAME_STATES } from '../../../constants';
import { SoundManager } from '../../../utils/soundManager';

export const Bomb = memo(() => {
  const state = useAtomValue(stateAtom);
  const crash = useAtomValue(crashAtom);
  const bustedImage = useRef<HTMLDivElement>(null);
  const sparkImage = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bustedImage.current?.classList.remove('static');
    sparkImage.current?.classList.remove('static');
    if (crash) {
      SoundManager.play('Bomb_Explode', false);
      setTimeout(() => {
        sparkImage.current?.classList.add('static');
      }, 1500);
      setTimeout(() => {
        bustedImage.current?.classList.add('static');
      }, 1200);
    }
  }, [crash]);

  useEffect(() => {
    if (state === GAME_STATES.GD && !crash) {
      SoundManager.play('Bomb_Ignite', true);
    } else {
      SoundManager.stop('Bomb_Ignite');
    }
  }, [state, crash]);

  return (
    <div
      className={classNames(
        'bombContainer',
        { lite: state === GAME_STATES.GD },
      )}
    >
      <div
        className={classNames(
          'bomb',
          { lite: state === GAME_STATES.GD },
          { crash: crash }
        )}
      />
      {crash ? (
        <div className={classNames('bustContainer', { crash: crash })}>
          <div className="busted" ref={bustedImage} />
          <div className="spark" ref={sparkImage} />
        </div>
      ) : null}
    </div>
  );
});
