import { Howl } from 'howler';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/scss/styles.scss';
import soundJson from './assets/sounds/output.json';
import mp3Sound from './assets/sounds/output.mp3';
import oggSound from './assets/sounds/output.ogg';
import wavSound from './assets/sounds/output.wav';
import webmSound from './assets/sounds/output.webm';
import reportWebVitals from './reportWebVitals';
import { SoundManager } from './utils/soundManager';
import { Preloader } from 'components/PreLoader';

let sound!: Howl;
try {
  sound = new Howl({
    src: [mp3Sound, oggSound, wavSound, webmSound],
    sprite: soundJson.sprite as any,
    loop: false,
    onload: (): void => {
      console.log('Sound is loaded');
    },
    onloaderror: (): void => {
      console.error('Unable to load sound');
    },
    preload: true,
  });
} catch (error) {
  console.log('Error in loading sound', error);
} finally {
  if (sound) {
    for (const key in soundJson.sprite) {
      const volume = (soundJson.volume as any)[key];
      SoundManager.setRegisterList(key, sound, volume);
    }
    SoundManager.mute();
  }
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
		<Preloader>
    <App />
		</Preloader>
  </React.StrictMode>
);

reportWebVitals();
