import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo } from 'react';
import '../../../assets/scss/components/game/_multiplier.scss';
import { crashAtom, multiplierAtom } from '../../../atoms';
import { Bomb } from '../bomb';

export const Multiplier = memo(() => {
  const multiplier = useAtomValue(multiplierAtom);
  const busted = useAtomValue(crashAtom);
  return (
    <div
      className={classNames(
        'gameMultiplier',
        { busted: busted },
      )}
    >
      <Bomb></Bomb>
      <div className="multiplerValue">
        {multiplier !== '' ? `${multiplier}x` : ''}
      </div>
    </div>
  );
});
