import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useEffect, useRef } from 'react';
import VillianMsgDesktop from '../../../assets/images/loader/msg-1-d.png';
import VillianMsgMobile from '../../../assets/images/loader/msg-1.png';
import HeroMsgDesktop from '../../../assets/images/loader/msg-2-d.png';
import HeroMsgMobile from '../../../assets/images/loader/msg-2.png';
import '../../../assets/scss/components/game/_loader.scss';
import { countDownAtom, isMobilePortraitAtom, stateAtom } from '../../../atoms';
import { GAME_STATES } from '../../../constants';
import { SoundManager } from '../../../utils';

export const Loader = memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const tempDiv = useRef<HTMLDivElement>(null);
  const villianRef = useRef<HTMLDivElement>(null);
  const state = useAtomValue(stateAtom);
  const time = useAtomValue(countDownAtom);
  const isMobilePortrait = useAtomValue(isMobilePortraitAtom);
  const loadingText =
    state === GAME_STATES.NOT_READY
      ? 'Loading game...'
      : 'Waiting for bets ...';
  let timeOut: any;

  useEffect(() => {
    if (time) SoundManager.play('Timer_Tick');
  }, [time]);

  useEffect(() => {
    window.addEventListener('orientationchange', onChangeOrientation);
    setTimeout(updateImages, 0);

    return () => {
      clearTimeout(timeOut);
      window.removeEventListener('orientationchange', onChangeOrientation);
    };
  }, []);

  const onChangeOrientation = (e: any) => {
    clearTimeout(timeOut);
    ref.current!.classList.remove('show');
    setTimeout(updateImages, 0);
  };

  const updateImages = () => {
    if(villianRef.current){
        villianRef.current.style.backgroundImage = `url(${
          isMobilePortrait
                ? VillianMsgMobile : VillianMsgDesktop
        }?${Math.random()})`;
    }
    timeOut = setTimeout(() => {
      if (ref.current) {
        ref.current.style.backgroundImage = `url(${
          isMobilePortrait
            ? HeroMsgMobile
            : HeroMsgDesktop
        }?${Math.random()})`;
        ref.current.classList.add('show');
        tempDiv.current?.classList.add('hide');
      }
    }, 2000);
  };

  return (
    <div className={classNames('loader')}>
      <div className="loaderGuide">
        <div className="villian" ref={villianRef}></div>
        <div className="temp" ref={tempDiv} />
        <div className="hero" ref={ref}></div>
      </div>
      {time !== null ? (
        <div className="loaderContent">
          <div className="shuffle"></div>
          <div className="loaderData">
            <span className="info">{loadingText}</span>
            {time !== null ? <span className="time">{`${time} s`}</span> : null}
          </div>
        </div>
      ) : null}
    </div>
  );
});
