import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useLayoutEffect, useState } from 'react';
import '../../../assets/scss/components/game/_autoplay.scss';
import { showAutoPlayAtom } from '../../../atoms';
import { EventEmitter } from '../../../utils';
import { AutoPlayOptions } from './options';

const autoPlayButtons = ['10', '20', '50', '100'];
const autoPlayOptions: Record<string, any> = [
  {
    id: 'stopOnDecrement',
    disabled: true,
    label: 'Stop if cash decreases by',
    value: 0,
    onChange: null,
    onClick: null,
  },
  {
    id: 'stopOnIncrement',
    disabled: true,
    label: 'Stop if cash increases by',
    value: 0,
    onChange: null,
    onClick: null,
  },
  {
    id: 'stopOnSingleWin',
    disabled: true,
    label: 'Stop if single win increases',
    value: 0,
    onChange: null,
    onClick: null,
  },
];
export function AutoPlay() {
  const [bet, setBet] = useState('');
  const [buttonId, setButtonId] = useState(-1);
  const [rounds, setRounds] = useState(autoPlayButtons[0]);
  const [isCashDecSWOn, setIsCashDecSWOn] = useState(false);
  const [isCashIncSWOn, setIsCashIncSWOn] = useState(false);
  const [isSingleWinSWOn, setIsSingleWinSWOn] = useState(false);
  const [cashDec, setCashDec] = useState(0);
  const [cashInc, setCashInc] = useState(0);
  const [singleWin, setSingleWin] = useState(0);
  const [error, setError] = useState('');
  const [, setAutoplayAtom] = useAtom(showAutoPlayAtom);

  autoPlayOptions[0].disabled = isCashDecSWOn;
  autoPlayOptions[0].onChange = setIsCashDecSWOn;
  autoPlayOptions[0].value = cashDec;
  autoPlayOptions[0].onClick = setCashDec;

  autoPlayOptions[1].disabled = isCashIncSWOn;
  autoPlayOptions[1].onChange = setIsCashIncSWOn;
  autoPlayOptions[1].value = cashInc;
  autoPlayOptions[1].onClick = setCashInc;

  autoPlayOptions[2].disabled = isSingleWinSWOn;
  autoPlayOptions[2].onChange = setIsSingleWinSWOn;
  autoPlayOptions[2].value = singleWin;
  autoPlayOptions[2].onClick = setSingleWin;

  useLayoutEffect(() => {
    EventEmitter.getInstance().addListener(
      'autoplayOpen',
      setStateForCurrentBet
    );

    return () => {
      EventEmitter.getInstance().removeListener(
        'autoplayOpen',
        setStateForCurrentBet
      );
    };
  }, []);

  const setStateForCurrentBet = (data: any) => {
    setBet(data.bet);
    setButtonId(data.buttonId);
  };
  const onClickRoundsButton = (value: string) => {
    setRounds(value);
  };
  const onClickErrorClose = () => {
    setError('');
  };

  const onClickReset = (): void => {
    setRounds(autoPlayButtons[0]);
    setIsCashDecSWOn(false);
    setIsCashIncSWOn(false);
    setIsSingleWinSWOn(false);
    setCashDec(0);
    setCashInc(0);
    setSingleWin(0);
    setError('');
  };
  const onClickSubmit = async (): Promise<void> => {
    if (!isCashDecSWOn && !isCashIncSWOn && !isSingleWinSWOn) {
      setError('Please enter autoplay limits');
      return;
    }

    if ( (isCashDecSWOn && cashDec < 0.01) || (isCashIncSWOn && cashInc < 0.01) ||
      (isSingleWinSWOn && singleWin < 0.01) ) {
      setError('Can not set 0.00 as stop point');
      return;
    }

    if( (isCashIncSWOn) && (!isCashDecSWOn && !isSingleWinSWOn) ) {
        setError('Please, specify decrease or exceed stop point');
        return;
    }

    if(isCashDecSWOn && cashDec > 10000) {
      setCashDec(10000);
    }

    if(isSingleWinSWOn && singleWin > 10000) {
      setSingleWin(10000);
    }

    try {
      const response = await EventEmitter.getInstance().emitAsync(
        'autoplayStart',
        {
          buttonId,
          amount: bet,
          totalRounds: rounds,
          cashDecLimit: isCashDecSWOn ? { value: cashDec } : null,
          cashIncLimit: isCashIncSWOn ? { value: cashInc } : null,
          singleWinLimit: isSingleWinSWOn ? { value: singleWin } : null,
        }
      );
      setAutoplayAtom(false);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="autoplayContainer">
      <div className="overlay" 
            onClick={() => {
              console.log ('clicked on overlay')
              setAutoplayAtom(false);
            }}
      />
      <div className="autoplayBox">
        <div className="autoplayHeader">
          <span className="title">Auto play options</span>
          <div
            className="fa fa-times closeBtn"
            onClick={() => {
              setAutoplayAtom(false);
            }}
          ></div>
        </div>
        <div className={classNames('errorContainer', { show: error !== '' })}>
          <span className="error">{error}</span>
          <div
            className="fa fa-times closeBtn"
            onClick={onClickErrorClose}
          ></div>
        </div>
        <div className="autoplayContent">
          <div className="roundsPanel">
            <span className="panelLabel">Number of Rounds:</span>
            <div className="roundButtons">
              {autoPlayButtons.map((btn) => (
                <div
                  key={btn}
                  className={classNames('roundButton', {
                    active: rounds === btn,
                  })}
                  onClick={() => onClickRoundsButton(btn)}
                >
                  {btn}
                </div>
              ))}
            </div>
          </div>
          <div className="optionsContainer">
            {autoPlayOptions.map((option: Record<string, any>) => (
              <AutoPlayOptions
                enabled={option.disabled}
                value={option.value}
                key={option.label}
                label={option.label}
                onClick={option.onClick}
                onChange={option.onChange}
              />
            ))}
          </div>
        </div>
        <div className="autoplayFooter">
          <button className="reset" onClick={onClickReset}>
            Reset
          </button>
          <button className="start" onClick={onClickSubmit}>
            Start
          </button>
        </div>
      </div>
    </div>
  );
}
