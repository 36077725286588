import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { SoundManager } from '../../../utils';
import { Suit } from './notification';

interface IVillianNotificationProps {
  suit: Suit;
  show: boolean;
}
export const VillianNotification = memo((props: IVillianNotificationProps) => {
  useEffect(() => {
    if (props.show) {
      SoundManager.play('Enemy_Flush_Warning_Start');
    }
  }, [props.show]);

  return (
    <div className={classNames('villian', props.suit, { show: props.show })}>
      !
    </div>
  );
});
