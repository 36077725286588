import { BUTTON_STATES } from '../constants';

type Street = {
  community: string[];
  hero: string[];
  villain: string[];
};
const CardMapper: Record<string, string> = {
  d: 'diamond',
  c: 'club',
  s: 'spade',
  h: 'heart',
};

let btnState = BUTTON_STATES.NOT_READY;

export function getBetButtonStateFromStatus(status: string): BUTTON_STATES {
  const prevState = btnState;
  switch (status) {
    case 'ready':
      return BUTTON_STATES.BET;
    case 'error':
      return BUTTON_STATES.ERROR;
    case 'pending':
      return BUTTON_STATES.CANCEL_PENDING;
    case 'sent':
      return BUTTON_STATES.CANCEL_ACCEPTED;
    case 'accepted':
      return BUTTON_STATES.CANCEL_ACCEPTED;
    case 'cancel-sent':
      return BUTTON_STATES.CANCELLING;
    case 'cashout-sent':
      return BUTTON_STATES.CASHOUT;
    default:
      return prevState;
  }
}

export function generateCardData(street: Street) {
  const heroCards = (street.hero || []).map((data: string) => {
    return mapCards(data, 'hero');
  });
  const villianCards = (street.villain || []).map((data: string) => {
    return mapCards(data, 'villian');
  });
  const communityCards = (street.community || []).map((data: string) => {
    return mapCards(data, 'community');
  });

  return {
    heroCards,
    villianCards,
    communityCards,
  };
}

function mapCards(data: string, type: string) {
  return {
    type,
    suit: CardMapper[data.split('')[1]],
    value: data.split('')[0],
  };
}
