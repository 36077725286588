import classNames from 'classnames';
import { useAtom } from 'jotai';
import { ChangeEvent, memo, useEffect, useState } from 'react';
import { showAutoPlayAtom } from '../../../atoms';
import { EventEmitter } from '../../../utils';
import { CheckBox } from '../../shared';

const MIN_CASHOUT = 1.01;
const MAX_CASHOUT = 9999.99;
const STEP_CASHOUT = 0.01;
const PRECISION = 2;

interface IAutoplayButtonProps {
  bet: number | string;
  buttonId: number;
  autoPlayData: Record<string, any>;
  autoCashData: Record<string, any>;
  isDisabled: boolean;
  onChangeAutoCashout: (newValue: Record<string, any>) => void;
}
export const AutoplayButtons = memo((props: IAutoplayButtonProps) => {
  const [isAutoCash, setIsAutoCash] = useState(false);
  const [autoStop, setAutoStop] = useState(MIN_CASHOUT.toFixed(2));
  const [autoplay, setAutoplayAtom] = useAtom(showAutoPlayAtom);
  const [autoCashData, setAutoCashData] = useState<Record<string, any>>(
    props.autoCashData || {state: 'off'}
  );

  const autoPlayData = props.autoPlayData || { status: 'off', roundsLeft: 0 };

  useEffect(() => {
    setAutoCashData(props.autoCashData);
    setIsAutoCash(props.autoCashData && props.autoCashData.state==='on');
  }, [props.autoCashData]);

  useEffect(() => {
    const instance = EventEmitter.getInstance();
    instance.addListener('setAutoCashData', onChangeAutoCashData);

    return () => {
      instance.removeListener('setAutoCashData', onChangeAutoCashData);
    };
  }, []);

  const onChangeAutoCashData = (data: any) => {
    setAutoCashData(data);
  };

  const onClickAutoCash = async (value: boolean) => {
    let response;
    setIsAutoCash(value);

    const cashoutmultiplier = Number(autoStop) > MIN_CASHOUT ? Number(autoStop) : MIN_CASHOUT;

    try {
      if (!isAutoCash) {
        [response] = await EventEmitter.getInstance().emitAsync('enableAutoCash', {
          buttonId: props.buttonId,
          multiplier: cashoutmultiplier,
        });
        setAutoCashData(response?.autoCashout);
        props.onChangeAutoCashout(response?.autoCashout);
      }
      else {
        [response] = await EventEmitter.getInstance().emitAsync('disableAutoCash',{
            buttonId: props.buttonId,
        });
        setAutoCashData(response?.autoCashout);
        props.onChangeAutoCashout(response?.autoCashout);
      }
    }
    catch (error: any) {
      console.error('Error in autocash', error);
    }
  };

  const onClickAutoplay = () => {
    if (autoPlayData.status === 'off') {
      setAutoplayAtom(true);
      setTimeout(() => {
        EventEmitter.getInstance().emit('autoplayOpen', {
          bet: props.bet,
          buttonId: props.buttonId,
        });
      }, 1);
    } else {
      EventEmitter.getInstance().emitAsync('autoplayStop', {
        buttonId: props.buttonId,
      });
    }
  };

  const handleBlur = () => {
    let value = Number(autoStop);

    if (value < MIN_CASHOUT || isNaN(value)) {
      value = MIN_CASHOUT;
    }
    else if (value > MAX_CASHOUT) {
      value = MAX_CASHOUT;
    }
    else {
      value = parseFloat((Math.round(value * 100) / 100).toString());
    }

    setAutoStop(value.toFixed(2));    
  };

  const handleChange = (e : any) => {
    let value = e.target.value;

    if (value === '') {
      setAutoStop('');
      return;
    }

    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1];
    } else {
      value = sanitizedValue;
    }

    setAutoStop(value);
  };

  return (
    <div className="autoplayButtons">
      <div className="buttonContainer">
        <span
          className={classNames('autoButton', autoPlayData.status)}
          onClick={onClickAutoplay}
        >
          {autoPlayData.status === 'on'
            ? `STOP (${autoPlayData.roundsLeft})`
            : 'AUTO PLAY'}
        </span>
      </div>
      <div className={classNames(`autoPlaySettings ${!props.isDisabled ? '' : 'isDisabled'}`)}>
        <CheckBox
          onClick={onClickAutoCash}
          checked={isAutoCash}
          className="oval"
          label="Auto Cash Out"
        ></CheckBox>
        <div className="stopAutoPlay">
        <input
            type="text" 
            step={STEP_CASHOUT.toFixed(PRECISION)} 
            value={autoStop}
            inputMode='decimal'
            disabled={isAutoCash}
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <div className="crossBtn">
          x
        </div>
       </div>
      </div>
    </div>
  );
});
