import classNames from "classnames";
import "../../../assets/scss/components/shared/_button.scss";
import { useCurrency } from "../../../hooks/useCurrency";

interface IButtonProps {
  type: any;
  label: string;
  value?: string | number;
  currency?: string;
  className?: string;
  onClick?: (value: number | string) => void;
}

export function Button(props: IButtonProps) {
  const [value, sign] = useCurrency(props.value, props.currency);
  const className = classNames("button", props.className, props.type);
  const currencyClass = value ? "d-block" : "d-none";

  const onClick = () => {
    props.onClick && props.onClick(props.value ? props.value : "");
  };

  return (
    <div className={className} onClick={onClick}>
      <p className="label">{props.label}</p>
      <div className={classNames(`currency ${currencyClass}`)}>
        <span className="value">{value}</span>
        {/* <span className="sign">{sign}</span> */}
      </div>
    </div>
  );
}
