import classNames from "classnames";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { isTMA } from "@telegram-apps/bridge";
import closebtn from "../../../assets/images/cashoutcardcancelbtn.png";
import "../../../assets/scss/components/game/_cashout-popup.scss";
import { cashoutDataAtom, cashoutNotifyAtom } from "./../../../atoms";

interface PopupType {
  id: number;
  data: {
    multiplier: number;
    amount: number;
  };
  closing: boolean;
}

export const CashoutPopup = memo(() => {
  const [showCashoutPopup, setShowCashoutPopup] = useAtom(cashoutNotifyAtom);
  const [cashoutData] = useAtom(cashoutDataAtom);
  const [popups, setPopups] = useState<PopupType[]>([]);

  useEffect(() => {
    if (showCashoutPopup) {
      const newPopup = {
        id: Date.now(),
        data: cashoutData,
        closing: false,
      };

      setPopups((prevPopups) => [...prevPopups, newPopup]);

      setTimeout(() => {
        setPopups((prevPopups) =>
          prevPopups.map((popup) =>
            popup.id === newPopup.id ? { ...popup, closing: true } : popup,
          ),
        );

        setTimeout(() => {
          setPopups((prevPopups) =>
            prevPopups.filter((popup) => popup.id !== newPopup.id),
          );
        }, 500);
      }, 3000);
    }
  }, [showCashoutPopup, cashoutData]);

  const handleClose = (id: number) => {
    setPopups((prevPopups) =>
      prevPopups.map((popup) =>
        popup.id === id ? { ...popup, closing: true } : popup,
      ),
    );

    setTimeout(() => {
      setPopups((prevPopups) => prevPopups.filter((popup) => popup.id !== id));
    }, 500);
  };

  return (
    <>
      {popups.map((popup) => (
        <div
          key={popup.id}
          className={classNames("cashout-popup", {
            mobile: isMobile || isTMA("simple"),
            opening: !popup.closing,
            closing: popup.closing,
          })}
          style={{
            top:
              isMobile || isTMA("simple")
                ? `${180 + popups.indexOf(popup) * 140}px`
                : `${100 + popups.indexOf(popup) * 120}px`,
          }}
        >
          <button
            onClick={() => handleClose(popup.id)}
            className="close-button"
          >
            <img src={closebtn} alt="Close" />
          </button>
          <div className="cashout-header">
            <span>
              You have CASHED OUT {Number(popup.data.multiplier).toFixed(2)}x
            </span>
          </div>
          <div className="cashout-body">
            <div className="win-notification">
              <span>You Win! {Number(popup.data.amount).toFixed(2)} USD</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
});
