import { memo, useCallback } from "react";
import { Error } from "../../shared";

export const SessionExpired = memo(() => {
  const onClickErrorButton = useCallback(() => {
    const queryParams = new URLSearchParams({
      gameId: "cp01",
      sessionId: "111111111",
    }).toString();

    window.location.href = `/?${queryParams}`;
    console.log("Handle session is expired");
  }, []);

  return (
    <Error
      className="sessionExpired"
      buttonLabel="START"
      errorCode=""
      errorMessage="WAIGER CRASH POKER"
      errorInfo=""
      onClickErrorButton={onClickErrorButton}
    />
  );
});
