import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { memo, useCallback, useState } from 'react';
import '../../../assets/scss/components/game/_gameHistory.scss';
import {
  historyItemsToFetchAtom,
  isMuteAtom,
  multiHistory,
  showHistoryReportAtom,
} from '../../../atoms';
import { HISTORY_SIZE } from '../../../constants';
import { EventEmitter, SoundManager } from '../../../utils';
import { Button, Drawer } from '../../shared';

export interface MultiHistory {
  roundId: string;
  crashPoint: Number;
}

export const GameHistory = memo(() => {
  const [, setShowHistoryReport] = useAtom(showHistoryReportAtom);
  const [, setHistoryItemsToFetch] = useAtom(historyItemsToFetchAtom);
  const [, setIsMute] = useAtom(isMuteAtom);
  const multipliers = useAtomValue(multiHistory);

  const [mute, setMute] = useState(true);
  const [open, setOpen] = useState(false);

  const onOpen = useCallback((value: boolean) => {
    setOpen(value);
    setHistoryItemsToFetch(
      value ? HISTORY_SIZE.MULTIPLIER.MAX : HISTORY_SIZE.MULTIPLIER.MIN
    );
    if (multipliers?.length < HISTORY_SIZE.MULTIPLIER.MAX)
      EventEmitter.getInstance().emit('fetchHistory', {
        type: 'multi',
        qty: HISTORY_SIZE.MULTIPLIER.MAX,
      });
  }, []);

  const onClickGameHistory = useCallback(() => {
    console.log('enable this for show report');
    //setShowHistoryReport(true);
  }, []);

  const getClassNames = (multiplier: Number): string => {
    if (Number(multiplier) >= 5) return 'monster';
    if (Number(multiplier) >= 2) return 'big';
    if (Number(multiplier) >= 1.5) return 'avg';
    if (Number(multiplier) >= 1) return 'mini';

    return 'bust';
  };

  const onClickAudioBtn = () => {
    setIsMute(!mute);
    setMute(!mute);
    if (!mute) {
      SoundManager.mute();
    } else {
      SoundManager.unmute();
    }
  };
  return (
    <div
      className={classNames(
        'gameHistory',
        { open: open },
        { close: !open },
      )}
    >
      <div
        className={classNames('audioBtn', { mute: mute })}
        onClick={onClickAudioBtn}
      ></div>
      <div className="header">
        <span>GAME HISTORY</span>
      </div>

      <Drawer onOpen={onOpen} className="multiplierDrawer" open={open}>
        {multipliers?.map((elem) => {
          const { roundId, crashPoint } = elem;
          return (
            <Button
              type="multiplier"
              label={`${crashPoint.toFixed(2)}x`}
              key={`data-${Math.random()}`}
              className={getClassNames(crashPoint)}
              onClick={onClickGameHistory}
            />
          );
        })}
      </Drawer>
    </div>
  );
});
