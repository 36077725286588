import { useAtomValue } from 'jotai';
import { ChangeEvent, useEffect, useState } from 'react';
import { betsArrayAtom } from '../../../atoms';
import { Button } from '../../shared';

const MIN_BET = 0.1;
const STEP_BET = 0.1;
const MAX_BET = 100;
const PRECISION = 2;

interface IButtonPanelProps {
  onClickIncrement: () => void;
  onClickDecrement: () => void;
  onClickBetButtons: (value: number) => void;
  onBetChange: (value: number | string) => void;
  betValue: number | string;
  disabled: boolean;
}

export function ButtonPanel(props: IButtonPanelProps) {
  const betsArray = useAtomValue(betsArrayAtom);
  const [bet, setBet] = useState(Number(props.betValue).toFixed(PRECISION));
  const onChange = (evt: ChangeEvent) => {
    let value = (evt.currentTarget as HTMLInputElement).value;
    
    if(value === ''){
      setBet('');
      return;
    }

    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1];
    } else {
      value = sanitizedValue;
    }

    setBet(value);
  };

  const onBlur = (evt: ChangeEvent) => {
    let b = Number(bet);

    if (b < MIN_BET)
      b = MIN_BET;

    if (b > MAX_BET)
      b = MAX_BET;

    setBet (b.toFixed(PRECISION));
    props.onBetChange (b.toFixed(PRECISION));
  };

  useEffect(() => {
    setBet(Number(props.betValue).toFixed(PRECISION));
  }, [props.betValue]);

  return (
    <div className={`changeBetPanel ${!props.disabled ? '' : 'disabled'}`}>
      <div className="panel">
        <Button type="oval" label="-" onClick={props.onClickDecrement} />
        <input
          className="betAmount"
          step={STEP_BET.toFixed(PRECISION)}
          inputMode="decimal"
          type="text"
          value={bet}
          onChange={onChange}
          onBlur={onBlur}
        ></input>
        <Button type="oval" label="+" onClick={props.onClickIncrement} />
      </div>

      <div className="buttonsSection">
        {betsArray.map((bet: number) => (
          <Button
            key={`${bet}`}
            type="bet"
            label={bet.toString()}
            value={bet}
            onClick={() => {
              props.onClickBetButtons(bet);
            }}
          />
        ))}
      </div>
    </div>
  );
}
