import EventEmitter from "eventemitter2";
import Log from "./utils/log";
import Transport from "./transport";
import JitterBuf from "./jitter-buf";
import ClockSync from "./clock-sync";
import MeterSession from "./meter-session";

const log = new Log("session");

class Session {
  #transport;
  #emitterReq;
  #sync;
  #jitterBuf;
  #meterSession;

  constructor({ gsProto, gsHost, gsPort, msProto, msHost, msPort }) {
    this.#transport = new Transport({
      host: gsHost,
      port: gsPort,
      proto: gsProto,
      path: "",
    });
    this.#emitterReq = new EventEmitter({ wildcard: false });
    this.#meterSession = new MeterSession({
      host: msHost,
      port: msPort,
      proto: msProto,
    });
    this.#jitterBuf = new JitterBuf({ transport: this.#transport });
  }

  get meterSession() {
    return this.#meterSession;
  }

  async connect() {
    this.#listenToTransport();
    await this.#transport.connect();
    await this.#meterSession.connect();
  }

  async authenticate(credentials) {
    const response = await this.#transport.request(
      /* ID   */ "authenticate-me",
      /* DATA */ credentials,
    );

    const { identity, pingConfig, jitterBufHoldTime, minGap } = response;

    this.#sync = new ClockSync({ transport: this.#transport });
    this.#transport.pingConfig = pingConfig;
    this.#jitterBuf.hold = jitterBufHoldTime;
    this.#jitterBuf.minGap = minGap;
    this.#jitterBuf.sync = this.#sync;

    this.#sync.start();

    await this.#meterSession.authenticate(credentials);

    return response;
  }

  async disconnect() {
    throw new Error("** To be implemented => disconnect **");
  }

  request(reqId, data = {}) {
    return this.#transport.request(reqId, data);
  }

  info(infoId, data = {}) {
    return this.#transport.info(infoId, data);
  }

  on(fqEvent, handler) {
    const _s = fqEvent.split("/");
    const qualifier = _s[0];

    switch (qualifier) {
      case "info":
        return this.#onInfo(fqEvent, handler);

      case "req":
      case "transport":
        return this.#transport.on(fqEvent, handler);

      case "clock":
        return this.#sync.on(fqEvent, handler);

      default:
        throw new Error(
          `unknown event qualifier "${qualifier}" for event "${fqEvent}"`,
        );
    }
  }

  #onInfo(fqEvent, handler) {
    const _s = fqEvent.split("/");
    const infoName = _s[1];

    switch (infoName) {
      case "starting":
      case "bwo":
      case "bwc":
      case "gd":
        return this.#jitterBuf.on(fqEvent, handler);
      default:
        return this.#transport.on(fqEvent, handler);
    }
  }

  resetStats() {
    return this.#transport.resetStats();
  }

  #listenToTransport() {
    this.on("transport/closed", (data) => {
      this.#handleClosed(data);
    });
    this.on("transport/disconnected", (data) => {
      this.#handleClosed(data);
    });
  }

  #handleClosed() {
    if (this.#sync) {
      this.#sync.stop();
      this.#sync = null;
    }
  }
}

export default Session;
