import classNames from 'classnames';
import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import warningImage from '../../../assets/images/warning.png';
import { errorCodeAtom } from '../../../atoms';

export const InsufficientFunds = memo(() => {
  const [, setErrorCode] = useAtom(errorCodeAtom);
  const onClickErrorButton = useCallback(() => {
    setErrorCode(null);
  }, []);

  return (
    <div className={classNames('errorPage', 'noFunds')}>
      <div className="insufficientFunds">
        <img src={warningImage} className="warning"></img>
        <span className="messages">Insufficient Balance</span>
        <div className="errorButton" onClick={onClickErrorButton}>
          OK
        </div>
      </div>
    </div>
  );
});
