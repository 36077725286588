import { useAtom, useAtomValue } from "jotai";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import {
  balanceAtom,
  cashoutDataAtom,
  cashoutNotifyAtom,
  communityCardsAtom,
  connectionStateAtom,
  countDownAtom,
  crashAtom,
  errorCodeAtom,
  gameIsStartedAtom,
  heroCardsAtom,
  historyItemsToFetchAtom,
  isAuthenticatedAtom,
  messageAtom,
  multiHistory,
  multiplierAtom,
  multiplierIdAtom,
  notificationDetailsAtom,
  playerIdAtom,
  potAndBustAtom,
  potAndBustHistory,
  refreshAtom,
  roundIdAtom,
  stateAtom,
  villianCardsAtom,
} from "../atoms";
import Session from "../comms/session.js";
import { INotification, Suit } from "../components";
import {
  CONN_STATES,
  GAME_STATES,
  LOW_JITTER_THRESHOLD,
  LOW_LATENCY_THRESHOLD,
} from "../constants";
import { EventEmitter } from "./eventEmitter";
import { generateCardData } from "./helper";
import { SoundManager } from "./soundManager";

export type BetData = {
  roundId?: string;
  amount: number;
  ts?: Date;
  buttonId: number;
};
export type CashOutData = {
  betId: string;
  roundId?: string;
  multiplier: number;
  ts?: Date;
  buttonId: number;
  multiplierId: string;
};
export type CancelData = {
  betId: string;
  roundId?: string;
  txnId: string;
  ts?: Date;
  buttonId: number;
};

export type History = {
  type?: string;
  qty: number;
};

type BetRequestData = BetData & { id: string };
type CashOutRequestData = CashOutData & { id: string };
type BalanceRequestData = { ts: Date };
type CancelRequestData = CancelData & { id: string; betId: string };
type Multiplier = {
  value: number | string;
  crash: boolean;
  id: string;
};
type BetWindowOpen = {
  street: Street;
  roundId: string;
  countdown: string;
};
type BetWindowClose = {
  street: Street;
  roundId: string;
};
type MetaData = {
  actor: string;
  type: string;
  cards: string[];
  suit: Suit;
};
type GameData = {
  street: Street;
  multi: Multiplier;
};

type Street = {
  community: string[];
  hero: string[];
  villain: string[];
  metadata: MetaData[];
};

interface ISession {
  on: (key: string, fn: (data: any) => void) => void;
  request: (
    key: string,
    data:
      | BetRequestData
      | CashOutRequestData
      | BalanceRequestData
      | CancelRequestData,
  ) => Promise<any>;
  authenticate: ({
    gameId,
    sessionId,
  }: {
    gameId: string;
    sessionId: string;
  }) => Promise<{ status: string }>;
  connect: () => Promise<void>;
  resetStats: () => void;
  readonly meterSession: {
    on: (key: string, fn: (data: any) => void) => void;
    request: (key: string, data?: History) => Promise<any>;
  };
}

let firstLoad = true;
let processedHeroFlushes = new Set<string>();
let previousCommunity: {
  type: string;
  suit: string;
  value: string;
}[] = [];
let _hCards = [];
let _vCards = [];

export const withStateManager = (WrappedComponent: any) => {
  return function WithStateManager() {
    let session: ISession;
    const [gsHost] = useState(process.env.REACT_APP_GAME_HOST);
    const [gsPort] = useState(process.env.REACT_APP_GAME_PORT);
    const [gsProto] = useState(process.env.REACT_APP_GAME_PROTO);
    const [msHost] = useState(process.env.REACT_APP_METERING_HOST);
    const [msPort] = useState(process.env.REACT_APP_METERING_PORT);
    const [msProto] = useState(process.env.REACT_APP_METERING_PROTO);

    const [, setConnectionState] = useAtom(connectionStateAtom);
    const [, setAuthentication] = useAtom(isAuthenticatedAtom);
    const [, setGameState] = useAtom(stateAtom);
    const [, setHeroCards] = useAtom(heroCardsAtom);
    const [, setVillianCards] = useAtom(villianCardsAtom);
    const [, setCommunityCards] = useAtom(communityCardsAtom);
    const [, setMultiplier] = useAtom(multiplierAtom);
    const [, setCrashed] = useAtom(crashAtom);
    const [, setMessage] = useAtom(messageAtom);
    const [, setGameIsStarted] = useAtom(gameIsStartedAtom);
    const [, setRoundId] = useAtom(roundIdAtom);
    const [, setBalance] = useAtom(balanceAtom);
    const [, setPlayerId] = useAtom(playerIdAtom);
    const [, setErrorCode] = useAtom(errorCodeAtom);
    const [, setRefresh] = useAtom(refreshAtom);
    const [, setPotAndBust] = useAtom(potAndBustAtom);
    const [, setPotAndBustHistory] = useAtom(potAndBustHistory);
    const [, setMultiHistory] = useAtom(multiHistory);
    const [, setNotitification] = useAtom(notificationDetailsAtom);
    const [, setCountDown] = useAtom(countDownAtom);
    const [, setMultiplierId] = useAtom(multiplierIdAtom);

    const playerId = useAtomValue(playerIdAtom);
    const roundId = useAtomValue(roundIdAtom);
    const refresh = useAtomValue(refreshAtom);
    const historyItemsToFetch = useAtomValue(historyItemsToFetchAtom);
    const queryParam = new URLSearchParams(window.location.search);
    const roundIdRef = useRef<string>(roundId);
    const [, setShowCashoutPopup] = useAtom(cashoutNotifyAtom);
    const [, setCashoutData] = useAtom(cashoutDataAtom);

    useEffect(() => {
      roundIdRef.current = roundId; // Keep the ref updated with the latest roundId
    }, [roundId]);

    useEffect(() => {
      if (!gsHost || !gsPort || session || refresh) return;
      setRefresh(false);
      (async () => {
        try {
          setGameState(GAME_STATES.NOT_READY);
          session = new Session({
            gsHost,
            gsPort,
            gsProto,
            msPort,
            msHost,
            msProto,
          });
          session.on("transport/closed", handleTransportClosed);
          session.on("transport/shutdown", handleTransportClosed);
          session.on("transport/disconnected", handleTransportClosed);

          await session.connect();
          setConnectionState(CONN_STATES.CONNECTED);
          const id = loadPlayer();
          await authenticate();
        } catch (err) {
          console.error("Error occurred", err);
          setErrorCode(401);
        }
      })();
    }, [refresh]);

    const loadPlayer = (): string => {
      let playerId = cookie.load("playerId");

      if (!playerId) {
        playerId = nanoid();
        cookie.save("playerId", playerId, { path: "/" });
      }
      setPlayerId(playerId);
      return playerId;
    };

    const generateFlushIdentifier = (cards: string[]): string => {
      return cards.join("-");
    };

    const authenticate = async (): Promise<void> => {
      const gi = queryParam.get("gameId") || "";
      const si = queryParam.get("sessionId") || "";
      const authenticate = await session!.authenticate({
        gameId: gi,
        sessionId: si,
      });
      const { balance, userId } = await session.request("balance", {
        ts: new Date(),
      });
      setBalance(balance);
      setPlayerId(userId);
      addInfoListener();
      addBetWindowCloseListener();
      addBetWindowOpenListener();
      addRoundEndListener();
      addPotsAndBustsListner();
      addGameDataListener();
      addBetStatusListener();
      addClockSyncListener();
      fetchHistory({ type: "multi", qty: historyItemsToFetch });
      fetchHistory({ type: "pnb", qty: 4 });

      EventEmitter.getInstance().addListener("fetchHistory", fetchHistory);
      EventEmitter.getInstance().addListener("placeBet", placeBet);
      EventEmitter.getInstance().addListener("cancelBet", cancelBet);
      EventEmitter.getInstance().addListener("cashOutBet", cashOutBet);
      EventEmitter.getInstance().addListener("autoplayStart", autoplayStart);
      EventEmitter.getInstance().addListener("autoplayStop", autoplayStop);
      EventEmitter.getInstance().addListener("enableAutoCash", enableAutoCash);
      EventEmitter.getInstance().addListener(
        "disableAutoCash",
        disableAutoCash,
      );

      setAuthentication(authenticate.status === "ok");
    };

    const handleTransportClosed = (props: any) => {
      const { code } = props;
      setConnectionState(CONN_STATES.DISCONNECTED);
      SoundManager.mute();
      setErrorCode(code);
    };

    const addInfoListener = (): void => {
      const notification: INotification = {
        villian: new Set(),
        hero: new Set(),
      };

      session!.on("info/starting", (data) => {
        setGameState(GAME_STATES.STARTING);
        setRoundId(data.roundId);
        setMultiplier("");
        setMultiplierId("");
        setHeroCards([]);
        setVillianCards([]);
        setCommunityCards([]);
        setCrashed(false);
        setGameIsStarted(false);
        setCountDown(null);
        setNotitification(notification);
        previousCommunity = [];
        _hCards = [];
        _vCards = [];
        session.resetStats();
        fetchHistory({ type: "multi", qty: historyItemsToFetch });
        fetchHistory({ type: "pnb", qty: 4 });
      });
    };

    const addBetWindowOpenListener = (): void => {
      session!.on("info/bwo", (data: BetWindowOpen) => {
        const { street, roundId, countdown } = data;
        const { heroCards, villianCards, communityCards } =
          generateCardData(street);

        setGameState(GAME_STATES.BWO);
        setRoundId(roundId);
        setCountDown(Number(countdown));
        if (_hCards.length !== heroCards.length) setHeroCards(heroCards);
        if (_vCards.length !== villianCards.length)
          setVillianCards(villianCards);
        setCommunityCards(communityCards);
        _hCards = street.hero;
        _vCards = street.villain;
      });
    };

    const addBetWindowCloseListener = (): void => {
      session!.on("info/bwc", (data: BetWindowClose) => {
        const { street, roundId } = data;
        const { heroCards, villianCards, communityCards } =
          generateCardData(street);

        setGameState(GAME_STATES.BWC);
        setRoundId(roundId);
        setCountDown(0);
        if (_hCards.length !== heroCards.length) setHeroCards(heroCards);
        if (_vCards.length !== villianCards.length)
          setVillianCards(villianCards);
        setCommunityCards(communityCards);
        setGameIsStarted(true);
        _hCards = street.hero;
        _vCards = street.villain;
      });
    };

    const addBetStatusListener = (): void => {
      session.on("info/bet-status", (data) => {
        const {
          buttonId,
          status,
          cashoutTxn,
          betTxn,
          roundId,
          error,
          autoCashout,
        } = data;
        switch (status) {
          case "cashout-sent":
            setCashoutData({
              multiplier: autoCashout?.cashoutMulti,
              amount: cashoutTxn.amount,
            });
            setShowCashoutPopup(true);
            setBalance(cashoutTxn.balance);
            break;
          case "accepted":
            setBalance(betTxn.balance);
            setRoundId(roundId);
            break;
          case "error":
          case "bet-error":
          case "cashout-error":
            if (error.message.toLowerCase().includes("insufficient balance")) {
              setMessage(error.message);
              error.statusCode &&
                error.statusCode === 400 &&
                setErrorCode(error.statusCode);
            } else {
              toast.error("Something went wrong", {
                toastId: "something-went-wrong-toast",
                theme: "light",
              });
            }
            break;
        }
        EventEmitter.getInstance().emit(`betStatusInfo${buttonId}`, data);
      });
    };

    const addRoundEndListener = (): void => {
      session.on("info/round-end", (data) => {});
      processedHeroFlushes.clear();
    };

    const addClockSyncListener = (): void => {
      session.on("clock/sync", (data) => {
        const { latency, offset, jitter, lastLatency } = data;
        // console.log (data, 'clock sync data');
        // if (
        //   latency >= LOW_LATENCY_THRESHOLD ||
        //   jitter >= LOW_JITTER_THRESHOLD
        // ) {
        if (lastLatency >= LOW_LATENCY_THRESHOLD) {
          toast.warn("Slow network! This can limit your gaming experience", {
            toastId: "slow-network-toast",
            theme: "light",
            autoClose: 3000,
          });
        }
      });
    };

    const addGameDataListener = (): void => {
      session!.on("info/gd", (data: GameData) => {
        setGameState(GAME_STATES.GD);
        const { multi, street } = data;
        const tempCards = [...previousCommunity];
        const notification: INotification = {
          villian: new Set(),
          hero: new Set(),
        };

        if (multi) {
          setMultiplierId(multi.id);
          setMultiplier(
            ((Number(multi.value) * 100) / 100).toFixed(2).toString(),
          );
          setCrashed(Boolean(multi.crash));
          if (Boolean(multi.crash)) {
            setNotitification(notification);
          }
        }

        if (street) {
          const { heroCards, villianCards, communityCards } =
            generateCardData(street);
          const { metadata } = street;
          let showLockFromVillian = false;
          let showLockFromHero = false;
          if (communityCards.length) {
            if (firstLoad) {
              if (_hCards.length !== heroCards.length) setHeroCards(heroCards);
              if (_vCards.length !== villianCards.length)
                setVillianCards(villianCards);
            }
            previousCommunity = communityCards;
            if (tempCards.length === 5) {
              setCommunityCards([tempCards[0], ...communityCards]);
            } else {
              setCommunityCards(communityCards);
            }
          } else {
            if (_hCards.length !== heroCards.length) setHeroCards(heroCards);
            if (_vCards.length !== villianCards.length)
              setVillianCards(villianCards);
          }

          if (metadata) {
            metadata.forEach((data: MetaData) => {
              if (data.actor === "villan") {
                //if (data.type === 'possible-flush')
                notification.villian.add(data.suit);
                if (data.type === "flush") {
                  if (showLockFromHero) notification.hero.add(data.suit);
                  else showLockFromVillian = true;
                }
              }
              if (data.actor === "hero" && data.type === "flush") {
                const flushIdentifier = generateFlushIdentifier(data.cards);

                if (!processedHeroFlushes.has(flushIdentifier)) {
                  processedHeroFlushes.clear();
                  processedHeroFlushes.add(flushIdentifier);
                  SoundManager.play("Hero_Flush", false);
                }

                if (showLockFromVillian) notification.hero.add(data.suit);
                else showLockFromHero = true;
              }
            });

            setNotitification(notification);
          }
          firstLoad = false;
          _hCards = street.hero;
          _vCards = street.villain;
        }
      });
    };

    const addPotsAndBustsListner = (): void => {
      session!.meterSession!.on("info/pnb", (data: any) => {
        setPotAndBust(data);
      });
    };

    const fetchHistory = async (args: History): Promise<void> => {
      const { type, qty } = args;

      try {
        const data = await session!.meterSession.request("history", {
          type,
          qty,
        });

        if (!data) throw new Error("Invalid history data");

        switch (type) {
          case "multi":
            return setMultiHistory(data);
          case "pnb":
            return setPotAndBustHistory(data);
        }
      } catch (err) {
        /** TODO need to show proper message on UI */
        console.error({ err }, `Failed to fetch ${type} history`);
      }
    };

    const placeBet = async (data: BetData): Promise<any> => {
      let response;
      try {
        console.warn("bet placed", data.amount);
        response = await session!.request("bet", {
          buttonId: data.buttonId,
          roundId: roundIdRef.current || "",
          amount: data.amount,
          ts: new Date(),
        });
        const { betTxn, roundId: rid } = response;
        if (betTxn) {
          setBalance(betTxn.balance);
        }
        if (rid) setRoundId(rid);

        return response;
      } catch (e: any) {
        console.error("Error in place bet", e);
        e.errorOccured = true;
        e.error = e;
        if (e.message.toLowerCase().includes("insufficient balance")) {
          setMessage(e.message);
          e.statusCode && e.statusCode === 400 && setErrorCode(e.statusCode);
        } else {
          toast.error("Something went wrong", {
            toastId: "something-went-wrong-toast",
            theme: "light",
          });
        }
        return e;
      }
    };

    const cancelBet = async (data: CancelData): Promise<any> => {
      let response;
      try {
        response = await session!.request("bet-cancel", {
          buttonId: data.buttonId,
          betId: data.betId,
          txnId: data.txnId,
          roundId: data.roundId,
          ts: new Date(),
        });
        const { status, error, betStatus } = response;
        if (status === "error") throw error;
        betStatus.cancelTxn && setBalance(betStatus.cancelTxn.balance);
      } catch (e: any) {
        console.error("Error in cancel bet", e);
        toast.error("Something went wrong", {
          toastId: "something-went-wrong-toast",
          theme: "light",
        });
        e.errorOccured = true;
        e.error = e;
        setMessage(e.message);
        response = e;
      } finally {
        return response;
      }
    };

    const cashOutBet = async (data: CashOutData): Promise<any> => {
      let response;
      try {
        console.warn("bet cashout", data.multiplier);
        response = await session!.request("cash-out", {
          id: playerId,
          roundId: roundIdRef.current,
          multiplier: data.multiplier,
          ts: new Date(),
          buttonId: data.buttonId,
          betId: data.betId,
          multiplierId: data.multiplierId,
        });
        const { cashoutTxn } = response;
        setCashoutData({
          multiplier: data.multiplier,
          amount: cashoutTxn.amount,
        });
        setShowCashoutPopup(true);
        setBalance(cashoutTxn.balance);
      } catch (e: any) {
        console.error("Error in cash out", e);
        toast.error("Something went wrong", {
          toastId: "something-went-wrong-toast",
          theme: "light",
        });
        e.errorOccured = true;
        e.error = e;
        setMessage(e.message);
        response = e;
      } finally {
        return response;
      }
    };

    const autoplayStart = async (data: any): Promise<void> => {
      let response;
      try {
        response = await session.request("start-auto-bet", {
          ...data,
        });
        console.log("Setting auto play start response", response);
        EventEmitter.getInstance().emit(
          "setAutoCashData",
          response.autoCashout,
        );
      } catch (e: any) {
        console.error("Error in autoplay start", e);
        e.errorOccured = true;
        e.error = e;
        setMessage(e.message);
        return e;
      }
    };

    const autoplayStop = async (data: any): Promise<void> => {
      let response;
      try {
        response = await session!.request("stop-auto-bet", {
          ...data,
        });
        const { status, betStatus } = response;
        status === "error" && setMessage(status.message);
        betStatus.cancelTxn && setBalance(betStatus.cancelTxn.balance);
        EventEmitter.getInstance().emit(
          "setAutoCashData",
          response.autoCashout,
        );
      } catch (e: any) {
        console.error("Error in autoplay stop", e);
        e.errorOccured = true;
        e.error = e;
        setMessage(e.message);
        return e;
      }
    };

    const enableAutoCash = async (data: any): Promise<any> => {
      let response;
      try {
        response = await session!.request("start-auto-cashout", {
          buttonId: data.buttonId,
          ...data,
        });
        const { status, betStatus } = response;
        status === "error" && setMessage(status.message);
        return response;
      } catch (e: any) {
        console.error("Error in starting autocashout", e);
        e.errorOccured = true;
        e.error = e;
        setMessage(e.message);
        return e;
      }
    };

    const disableAutoCash = async (data: any): Promise<any> => {
      let response;
      try {
        response = await session!.request("stop-auto-cashout", {
          ...data,
        });
        const { status } = response;
        status === "error" && setMessage(status.message);
        return response;
      } catch (e: any) {
        console.error("Error in stopping auto cashout", e);
        e.errorOccured = true;
        e.error = e;
        setMessage(e.message);
        return e;
      }
    };

    return <WrappedComponent />;
  };
};
