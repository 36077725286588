import classNames from 'classnames';
import { memo } from 'react';
import '../../../assets/scss/components/shared/_error.scss';

interface IErrorProps {
  onClickErrorButton: () => void;
  buttonLabel: string;
  errorCode: string | number;
  errorMessage: string;
  errorInfo: string;
  className?: string;
}

export const Error = memo((props: IErrorProps) => {
  return (
    <div
      className={classNames('errorPage', props.className)}
    >
      <p className="code">{props.errorCode}</p>
      <p className="message">{props.errorMessage}</p>
      <p className="info">{props.errorInfo}</p>
      <div className="errorButton" onClick={props.onClickErrorButton}>
        {props.buttonLabel}
      </div>
    </div>
  );
});
