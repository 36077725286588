import { Howl } from 'howler';
export class SoundManager {
  private static regiterList: { [key: string]: Howl } = {};
  private static registeredVolumes: { [key: string]: number } = {};

  private static runningSound: { [key: string]: number[] } = {};

  private static isMute = false;
  private static isInitiated = false;

  static setRegisterList(id: string, value: Howl, volume: any = 1): void {
    this.regiterList[id] = value;
    this.registeredVolumes[id] = volume;
  }

  static getIsMute(): boolean {
    return this.isMute;
  }

  static getRunningSounds(): { [key: string]: number[] } {
    return this.runningSound;
  }

  static play(id: string, loop = false): void {

    if (this.regiterList[id]) {
      if (!this.runningSound[id]) {
        this.runningSound[id] = [];
      }

      const sound: Howl = this.regiterList[id];

      const complete = (soundId: number): void => {
        sound.stop(soundId);
        this.runningSound[id].splice(this.runningSound[id].indexOf(soundId));
        sound.off('end', complete);
      };

      if (!this.isInitiated) {
        this.isInitiated = !this.isInitiated;
        try {
          const audioContext =
            window.AudioContext || (window as any).webkitAudioContext;
          const ctx = new audioContext();

          if (ctx.state !== 'running') {
            sound.once('playerror', () => {
              sound.off('play');
            });
            sound.once('play', () => {
              sound.off('playerror');
            });
          }
        } catch (error) {
          console.error('Error in playing sounds', error);
        }
      }
      const soundId: number = sound.play(id);
      sound.volume(this.registeredVolumes[id], soundId);
      if (loop) {
        sound.loop(loop, soundId);
      } else {
        sound.once('end', complete, soundId);
      }
      this.runningSound[id].push(soundId);
    }
  }

  static stop(id: string) {
    if (this.runningSound[id]) {
      for (const sound of this.runningSound[id]) {
        this.regiterList[id].stop(sound);
      }
      delete this.runningSound[id];
    }
  }

  static mute(): void {
    this.isMute = true;
    Howler.mute(this.isMute);
  }

  static unmute(): void {
    this.isMute = false;
    Howler.mute(this.isMute);
  }
}
