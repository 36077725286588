import EventEmitter from "eventemitter2";
import Log from "./utils/log";
import Transport from "./transport";
import ClockSync from "./clock-sync";

const log = new Log("session");

class MeterSession {
  #transport;

  constructor({ proto, host, port }) {
    this.#transport = new Transport({ host, port, proto, path: "" });
  }

  async connect() {
    this.#listenToTransport();
    await this.#transport.connect();
  }

  async authenticate(credentials) {
    const response = await this.#transport.request(
      /* ID   */ "authenticate-me",
      /* DATA */ credentials,
    );

    const { identity, pingConfig } = response;
    this.#transport.pingConfig = pingConfig;

    return response;
  }

  async getHistory(args) {
    const response = await this.#transport.request("history", args);

    const { history, pingConfig } = response;
    this.#transport.pingConfig = pingConfig;

    return response;
  }

  async disconnect() {
    throw new Error("** To be implemented => disconnect **");
  }

  request(reqId, data = {}) {
    return this.#transport.request(reqId, data);
  }

  info(infoId, data = {}) {
    return this.#transport.info(infoId, data);
  }

  on(fqEvent, handler) {
    return this.#transport.on(fqEvent, handler);
  }

  #listenToTransport() {
    this.on("transport/closed", (data) => {
      this.#handleClosed(data);
    });
    this.on("transport/disconnected", (data) => {
      this.#handleClosed(data);
    });
  }

  #handleClosed() {
    // if (this.#sync) {
    //     this.#sync.stop ();
    //     this.#sync = null;
    // }
  }
}

export default MeterSession;
