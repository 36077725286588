export const useCurrency = (value?: string | number, currency?: string) => {
  if (!value) return [];

  let sign = '';

  if (currency) {
    sign = 'USD';
  } else return [value, sign];

  return [((Number(value) * 100) / 100).toFixed(2).toString(), sign];
};
