import classNames from "classnames";
import { useAtomValue } from "jotai";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import "../../../assets/scss/components/game/_header.scss";
import {
  crashAtom,
  errorCodeAtom,
  potAndBustAtom,
  potAndBustHistory,
  stateAtom,
} from "../../../atoms";
import { Drawer } from "../../shared";
import { Balance } from "./balance";
import { PotAndBust } from "./potAndBust";
import { SoundManager } from "../../../utils";
import { GAME_STATES } from "../../../constants";

export const Header = memo(() => {
  const [open, setOpen] = useState(false);

  const state = useAtomValue(stateAtom);
  const crash = useAtomValue(crashAtom);
  const errorCode = useAtomValue(errorCodeAtom);
  const pnbData = useAtomValue(potAndBustAtom);
  const pnbHistory = useAtomValue(potAndBustHistory);

  const onOpen = useCallback((value: boolean) => {
    setOpen(value);
  }, []);

  useEffect(() => {
    switch (state) {
      case GAME_STATES.STARTING:
        SoundManager.play("Main_Screen_BGM", true);
        break;
      case GAME_STATES.GD:
        SoundManager.stop("Main_Screen_BGM");
        SoundManager.play("Ingame_Screen_BGM", true);
    }

    if (crash) SoundManager.stop("Ingame_Screen_BGM");
  }, [state, crash]);

  return (
    <div
      className={classNames("gameHeader", {
        error: errorCode && errorCode !== 401 && errorCode !== 400,
      })}
    >
      {/* <div className="menu"></div> */}
      {/* <div className="logo"></div> */}
      {(!errorCode || errorCode === 401 || errorCode === 400) && (
        <Fragment>
          <div className="gameInfo">
            {/* <div className="bomb"></div>
            <div className="info">
              <p className="primary">Bust Protection - Free Money!</p>
              <p className="secondary">
                You can never bust when.. the hero’s <br></br> hand is beating
                the villain’s hand.
              </p>
            </div> */}
          </div>
          <Balance />
          {/* <Drawer className="potAndBust" onOpen={onOpen} open={open}>
            <PotAndBust {...pnbData} />
            {pnbHistory.map((data, index) => (
              <PotAndBust {...data} key={`key-${index}`} />
            ))}
          </Drawer> */}
          <div className="userAvatar"></div>
        </Fragment>
      )}
    </div>
  );
});
