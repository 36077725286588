import classNames from 'classnames';
import { memo, useRef } from 'react';
import '../../../assets/scss/components/shared/_checkbox.scss';

interface ICheckBoxProps {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  checkedText?: string;
  unCheckedText?: string;
  onClick?: (checked: boolean) => void;
  label?: string;
}
export const CheckBox = memo((props: ICheckBoxProps) => {
  const ref = useRef(null);
  const className = classNames('checkbox', props.className);

  const onClickSlider = () => {
    if (ref.current) {
      const checked = (ref.current as HTMLInputElement).checked;
      props.onClick && props.onClick(!checked);
    }
  };

  return (
    <div className="container">
      <label className="label">{props.label}</label>
      <div className="checkboxContainer">
        <input
          type="checkbox"
          ref={ref}
          className={className}
          checked={props.checked}
          disabled={props.disabled}
          onChange={() => {}}
        ></input>
        <div className={classNames('slider', { disabled: props.disabled })} onClick={onClickSlider}>
          <span className="text">{props.checkedText}</span>
          <span className="text">{props.unCheckedText}</span>
        </div>
      </div>
    </div>
  );
});
