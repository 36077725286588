import classNames from 'classnames';
import { memo, ReactNode, useEffect, useRef, useState } from 'react';
import '../../../assets/scss/components/shared/_drawer.scss';

interface ICheckBoxProps {
  className?: string;
  onOpen: (open: boolean) => void;
  children: ReactNode;
  open: boolean;
}

export const Drawer = memo((props: ICheckBoxProps) => {
  const [open, setOpen] = useState(props.open);
  const className = classNames(
    'drawer',
    props.className,
    { open: props.open },
    { close: !props.open }
  );
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        setOpen(false);
        props.onOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, props.open]);

  const openDrawer = () => {
    setOpen(!open);
    props.onOpen && props.onOpen(!open);
  };

  return (
    <div className={className} ref={drawerRef}>
      <div className="drawerGroup">{props.children}</div>
      <div
        className={classNames('openButton', { open: props.open })}
        onClick={openDrawer}
      >
        &lt;
      </div>
    </div>
  );
});
