import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useRef } from 'react';
import { stateAtom, villianCardsAtom } from '../../../atoms';
import { GAME_STATES } from '../../../constants';
import { SoundManager } from '../../../utils/soundManager';
import { Card } from './card';

export const VillianCards = memo(() => {
  const villianCards = useAtomValue(villianCardsAtom);
  const state = useAtomValue(stateAtom);
  const ref = useRef(null);
  if (villianCards.length) {
    SoundManager.stop('Dealing-cards-2');
    SoundManager.play('Dealing-cards-2', false);
  }
  return (
    <div className="playerCard">
      <div className={classNames('avatar', 'villian')}>
        <span className="villian">VILLIAN</span>
      </div>
      {villianCards!.map((detail, index: number) => (
        <Card
          key={`${detail.suit}-${detail.value}${Math.random()}`}
          {...detail}
          hidden={
            index === villianCards!.length - 1 && state !== GAME_STATES.GD
          }
          ref={ref}
        />
      ))}
    </div>
  );
});
