import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import '../../../assets/scss/components/game/_historyReport.scss';
import { showHistoryReportAtom } from '../../../atoms';

const clientSeedData = [
  {
    playerId: 'Player N1',
    playerName: 'd***8',
    seed: 'lHHdLBnPN1Z3VE0FrGh3',
  },
  {
    playerId: 'Player N2',
    playerName: 'd***6',
    seed: 'lHHdLBnPN1Z3VE0FrGh3',
  },
  {
    playerId: 'Player N3',
    playerName: 'd***3',
    seed: 'lHHdLBnPN1Z3VE0FrGh3',
  },
];

export const HistoryReport = memo(() => {
  const [_, setShowHistoryReportAtom] = useAtom(showHistoryReportAtom);
  const onClickCloseBtn = useCallback(() => {
    setShowHistoryReportAtom(false);
  }, []);
  return (
    <div className="historyReportContainer">
      <div className="overlay" />
      <div className="historyReport">
        <div className="reportHeader">
          <div className="reportDetails">
            <span className="roundId">ROUND 5468864</span>
            <span className="reportMultiplier">1.53x</span>
            <span className="reportTime">18:22:48</span>
          </div>
          <div className="fa fa-times closeBtn" onClick={onClickCloseBtn} />
        </div>
        <div className="reportContent">
          <div className="serverDetails seed">
            <div className="seedHeader">
              <div className="seedImage serverSeedImage" />
              <div className="seedDetails">
                <span className="seedDetailHeading">Server Seed:</span>
                <span className="seedDetailsSubheading">
                  Generated on our side
                </span>
              </div>
            </div>
            <div className="seedData serverSeedData">
              <div className="seedDataContent serverSeedContent">
                3FpvSbKV2rQgA8Wl1EkDHwkJloNp6bP8GUoYdTAa
              </div>
            </div>
          </div>
          <div className="clientDetails seed">
            <div className="seedHeader">
              <div className="seedImage clientSeedImage" />
              <div className="seedDetails">
                <span className="seedDetailHeading">Client Seed:</span>
                <span className="seedDetailsSubheading">
                  Generated on player side
                </span>
              </div>
            </div>
            <div className="seedData clientSeedData">
              {clientSeedData.map((seed) => {
                return (
                  <div className="seedDataContent clientSeedContent">
                    <div className="playerData">
                      <span className="playerId">{`${seed.playerId}:`}</span>
                      <div className="playerDetails">
                        <div className="playerAvatar" />
                        <span className="playerName">{seed.playerName}</span>
                      </div>
                    </div>
                    <div className="seedId">
                      <span className="seedLabel">Seed:</span>
                      <span className="seedValue">{seed.seed}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="hashDetails seed">
            <div className="seedHeader">
              <div className="seedImage hashSeedImage" />
              <div className="seedDetails">
                <span className="seedDetailHeading">Combined SHA512 Hash:</span>
                <span className="seedDetailsSubheading">
                  Above seeds combined and converted to SHA512 Hash. This is
                  your game result
                </span>
              </div>
            </div>
            <div className="seedData hashSeedData">
              <div className="seedDataContent hashSeedContent">
                0c6efbee3149de1f3e661bd95530ef75c89b159beef4430f3c60e823a054823b07e0443166876aace76b0ed13f3e965cb19c351b0aad4666833d5c18286897c3
              </div>
            </div>
          </div>
          <div className="hexDetails seed">
            <div className="seedHeader hexSeedHeader">
              <div className="seedDetails">
                <span className="seedDetailHeading">Hex:</span>
              </div>
            </div>
            <div className="seedData hexSeedData">
              <div className="seedDataContent">0c6efbee3149d</div>
            </div>
          </div>
          <div className="decimalDetails seed">
            <div className="seedHeader decimalSeedHeader">
              <div className="seedDetails">
                <span className="seedDetailHeading">Decimal:</span>
              </div>
            </div>
            <div className="seedData decimalSeedData">
              <div className="seedDataContent">218733002036381</div>
            </div>
          </div>
          <div className="resultDetails seed">
            <div className="seedHeader resultSeedHeader">
              <div className="seedDetails">
                <span className="seedDetailHeading">Result:</span>
              </div>
            </div>
            <div className="seedData resultSeedData">
              <div className="seedDataContent">1.53</div>
            </div>
          </div>
        </div>
        <div className="reportFooter">
          <span className="instruction">For instructions check</span>
          <span className="link">What is Provably Fair</span>
        </div>
      </div>
    </div>
  );
});
